import { Document, Page, Text, View } from '@react-pdf/renderer';
import format from 'date-fns/format';

import { BlockWithLabelAndMdf } from 'api/mdfBlocks/types';
import { EditorValue, Note } from 'types';
import { OrderWithMdf } from 'types/forms/forms';
import { Mdf, MemberType } from 'types/graphqlTypes';

import CommonDocFooter from '../components/CommonDocFooter';

import { getDocumentComponent } from './utils';

import { styles } from './styles';

const NoteDocHeader = ({ note }: { note: Note }) => {
  const { mTitle, mType, mCreatedAt } = note;

  return (
    <View style={styles.header} fixed>
      <Text style={styles.name}>{mType}</Text>
      <Text style={styles.title}>{mTitle || 'Untitled'}</Text>
      <Text style={styles.scheduleInfo}>
        Created At: {format(mCreatedAt, 'MMM D YYYY, HH:mm:ss (Z)')}
      </Text>
      <View style={[styles.horizontalLine, { paddingTop: '8' }]} />
    </View>
  );
};

const NoteContents = ({
  content,
  blocks,
  orders,
  mdfsSeparated,
  relationMembers,
  contacts,
}: Pick<
  Props,
  'blocks' | 'orders' | 'mdfsSeparated' | 'content' | 'relationMembers' | 'contacts'
>) => {
  const { document = [] } = content;

  if (document && document.length > 0) {
    return (
      <View style={[styles.column, styles.content]}>
        {document.map((doc) => {
          return getDocumentComponent({
            doc,
            blocks,
            orders,
            mdfsSeparated,
            relationMembers,
            contacts,
          });
        })}
      </View>
    );
  }

  return null;
};

interface Props {
  note: Note;
  content: EditorValue;
  blocks: BlockWithLabelAndMdf[];
  orders: OrderWithMdf[];
  mdfsSeparated: {
    defaults: Mdf[];
    custom: Mdf[];
    subTypes: Mdf[];
    instances: Mdf[];
  };
  relationMembers: MemberType[];
  contacts: MemberType[];
}

const NotePrintDoc = ({
  note,
  content,
  blocks,
  orders,
  mdfsSeparated,
  relationMembers,
  contacts,
}: Props) => (
  <Document>
    <Page style={styles.body}>
      <NoteDocHeader note={note} />
      <NoteContents
        content={content}
        blocks={blocks}
        orders={orders}
        mdfsSeparated={mdfsSeparated}
        relationMembers={relationMembers}
        contacts={contacts}
      />
      <CommonDocFooter />
    </Page>
  </Document>
);

export default NotePrintDoc;
