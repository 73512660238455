/* eslint-disable import/no-extraneous-dependencies */
import { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { isEqual, keyBy } from 'lodash';

import { useCreateIntegration } from 'api/config/useCreateIntegration';
import { useDeleteIntegration } from 'api/config/useDeleteIntegration';
import { useGetIntegrationsForAdmin } from 'api/config/useGetIntegrations';
import { Button, IconButton } from 'components/buttons';
import useActionTrigger from 'components/contextMenu/useActionTrigger';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import { useEditStringDialog } from 'components/editStringDialog/EditStringDialog';
import { Input } from 'components/input/styled';
import ChoiceField from 'components/mdfEditor/fields/choice/ChoiceField';
import { CloseIcon } from 'components/orderFormDialog/styled';
import Scrollbar from 'components/scrollbar';
import SplitBar from 'components/split/SplitBar';
import Text from 'components/text/Text';
import useToast from 'components/toast/useToast';
import { HStack } from 'layouts/box/Box';
import {
  ConfigurableActionMTypes,
  FieldTypeEnum,
  IntegrationEnum,
  IntegrationType,
  LayoutSettings,
  MdfField,
  MemberTypeEnum,
} from 'types/graphqlTypes';

import { useChangedActions } from '../../atomsTs';
import { HeaderCell } from '../settingsTabs/systemSettings/details/studios/styled';

import { EditActionRow } from './EditActionRow';

export const programmaticIdRegex = /^[a-zA-Z0-9-_]*$/;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const columns = [
  { key: 'mTitle', name: 'Menu label' },
  { key: 'mDescription', name: 'Description' },
  { key: 'iconUrl', name: 'Icon URL (24x24px)' },
  { key: 'mTypes', name: 'Types' },
  { key: 'auth', name: 'Auth' },
  { key: 'endpoint', name: 'Endpoint' },
  { key: 'mActive', name: 'Active' },
  { key: 'mUpdatedAt', name: 'Updated' },
  { key: 'action', name: '' },
];

const layoutSettingsType: LayoutSettings = {
  visible: true,
  fieldId: 'mTypes',
  hint: '',
  label: '',
};

const mTypeField: MdfField = {
  fieldId: 'mTypes',
  type: FieldTypeEnum.choice,
  defaultValue: { value: MemberTypeEnum.Story },
  alternatives: [
    {
      id: MemberTypeEnum.Story,
      label: 'Story',
      value: MemberTypeEnum.Story,
    },
    {
      id: MemberTypeEnum.Instance,
      label: 'Instance',
      value: MemberTypeEnum.Instance,
    },
  ],
};

export function EditActions() {
  const { trigger } = useActionTrigger();
  const [resourceId, setResourceId] = useState('');
  const [resourceMType, setResourceMType] = useState<ConfigurableActionMTypes>(
    MemberTypeEnum.Story,
  );
  const [selectedAction, setSelectedAction] = useState<IntegrationType | null>(null);
  const { integrations: actions } = useGetIntegrationsForAdmin(IntegrationEnum.CustomAction);
  const { integrations: searchPlugins } = useGetIntegrationsForAdmin(IntegrationEnum.SearchPlugin);
  const { createIntegration } = useCreateIntegration();
  const { deleteIntegration } = useDeleteIntegration();
  const { errorToast } = useToast();
  const [changedActions, setChangedActions] = useChangedActions();
  const [actionToDelete, setActionToDelete] = useState<IntegrationType | null>(null);
  const [, showEditStringDialog] = useEditStringDialog();

  const actionsById = useMemo(() => {
    return keyBy(actions, (act) => act.mRefId);
  }, [actions]);

  const onDelete = useCallback(
    (id: string) => {
      const action = actions.find((a) => a.mRefId === id);
      if (action) {
        setActionToDelete(action);
      }
    },
    [setActionToDelete, actions],
  );

  const onResetAction = useCallback(
    (id: string) => {
      if (changedActions[id]) {
        const copy = { ...changedActions };
        delete copy[id];
        setChangedActions(copy);
      }
    },
    [setChangedActions, changedActions],
  );

  const doDeleteAction = useCallback(
    (actionIdToDelete: string | undefined) => {
      if (actionIdToDelete) {
        deleteIntegration(actionIdToDelete, IntegrationEnum.CustomAction)
          .then(() => {
            if (changedActions[actionIdToDelete]) {
              const copy = { ...changedActions };
              delete changedActions[actionIdToDelete];
              setChangedActions(copy);
            }
          })
          .catch(errorToast);
      }
      setActionToDelete(null);
    },
    [deleteIntegration, changedActions, setChangedActions, setActionToDelete],
  );

  const doCreateAction = useCallback(() => {
    showEditStringDialog({
      headerText: 'Create new custom action',
      required: true,
      inputLabel: 'Identifier',
      validator: (val: string) => {
        if (val.length < 3) {
          return 'Minimum length is 3 characters';
        }
        if (!programmaticIdRegex.test(val)) {
          return 'Only alphanumeric characters allowed';
        }
        if (actionsById[val]) {
          return 'Action already exists';
        }
        return true;
      },
      onConfirm: (val) => {
        createIntegration(val, IntegrationEnum.CustomAction).catch(errorToast);
      },
    });
  }, [showEditStringDialog, createIntegration, actionsById]);

  const onActionChange = useCallback(
    (actionUpdates: Partial<IntegrationType>, originalAction: IntegrationType) => {
      const updatedAction: IntegrationType = {
        ...originalAction,
        ...actionUpdates,
      };
      if (!changedActions[originalAction.mRefId] && !isEqual(updatedAction, originalAction)) {
        setChangedActions((prevState) => {
          return {
            ...prevState,
            [originalAction.mRefId]: updatedAction,
          };
        });
      } else if (changedActions[originalAction.mRefId]) {
        const updatedChangedAction: IntegrationType = {
          ...changedActions[originalAction.mRefId],
          ...actionUpdates,
        };
        if (isEqual(updatedChangedAction, originalAction)) {
          const copy = { ...changedActions };
          delete copy[originalAction.mRefId];
          setChangedActions(copy);
        } else {
          setChangedActions((prevState) => {
            return {
              ...prevState,
              [originalAction.mRefId]: updatedChangedAction,
            };
          });
        }
      }
    },
    [changedActions, setChangedActions],
  );

  const doTrigger = useCallback(
    (action: IntegrationType) => {
      trigger(
        action,
        {
          actionId: action.mRefId,
          resourceId: resourceId,
          resourceType: resourceMType,
        },
        resourceId,
      );
    },
    [trigger, resourceId, resourceMType],
  );

  const memoizedTable = useMemo(() => {
    return (
      <Scrollbar
        valueChanged={undefined}
        closeToBottom={undefined}
        top={undefined}
        bottom={undefined}
        dark={undefined}
        showHorizontal={undefined}
      >
        <Table aria-label="actions-table">
          <TableHead key="actions-table-head">
            <TableRow key="actions-header">
              <HeaderCell key="externalId">
                <HStack justifyContent="start">
                  <IconButton
                    title="Create new action"
                    size={24}
                    iconSize={20}
                    usage="text"
                    onClick={doCreateAction}
                  >
                    <Add />
                  </IconButton>
                  Id
                </HStack>
              </HeaderCell>
              {columns.map((col) => (
                <HeaderCell key={col.key}>{col.name}</HeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody key="actions-table-body">
            {actions.map((act) => (
              <EditActionRow
                availableSearchPluginTypes={searchPlugins}
                hasChanges={changedActions[act.mRefId] !== undefined}
                key={act.mRefId}
                action={changedActions[act.mRefId] ?? act}
                isSelected={act.mRefId === selectedAction?.mRefId}
                onSelect={(action) => setSelectedAction(action)}
                onDeleteAction={onDelete}
                onUpdateAction={(val) => onActionChange(val, act)}
                onResetAction={onResetAction}
              />
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    );
  }, [
    columns,
    actions,
    changedActions,
    selectedAction,
    doCreateAction,
    setSelectedAction,
    onDelete,
    onActionChange,
    onResetAction,
  ]);

  return (
    <Wrapper>
      {selectedAction ? (
        <SplitBar
          split={undefined}
          style={{
            height: '100%',
          }}
          primary="second"
          pane1Style={{
            minWidth: '500px',
          }}
          pane2Style={{
            minWidth: '250px',
            maxWidth: '500px',
          }}
        >
          {memoizedTable}
          <div style={{ width: '100%', height: '100%', padding: '8px' }}>
            <CloseIcon
              style={{ position: 'absolute', top: '3px', right: '3px' }}
              onClick={() => setSelectedAction(null)}
            />
            <Text variant="overline">Resource identifier (eg, story id)</Text>
            <Input
              value={resourceId}
              onChange={(ev) => setResourceId(ev.target.value)}
              placeholder="Enter resource id to send"
            />
            <ChoiceField
              editorId="EditActions"
              fieldModel={mTypeField}
              fieldSettings={null}
              defaultFieldSettings={layoutSettingsType}
              value={resourceMType}
              setValue={(val) => setResourceMType(val as ConfigurableActionMTypes)}
              errorMessage={undefined}
              style={{}}
              view="default"
            />
            <Button onClick={() => doTrigger(selectedAction)} disabled={!resourceId}>
              Trigger {selectedAction?.externalId}
            </Button>
          </div>
        </SplitBar>
      ) : (
        <>{memoizedTable}</>
      )}
      <DeleteDialog
        open={actionToDelete !== null}
        onClose={() => setActionToDelete(null)}
        onClick={() => doDeleteAction(actionToDelete?.mRefId)}
        title="Delete custom action?"
        message={`Are you sure you want to delete ${actionToDelete?.mTitle}?`}
      />
    </Wrapper>
  );
}
