import { useRef } from 'react';
import { atom, useAtom, useAtomValue } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { createScope, molecule, useMolecule } from 'jotai-molecules';

import { Instance, Note } from 'types';

export type MousePosition = {
  x: number | null;
  y: number | null;
};

export const initialMousePosition = {
  x: null,
  y: null,
};

export const PaneScope = createScope<`${string}:${number}` | undefined>(undefined);

const storyPaneMolecule = molecule((_getMol, getScope) => {
  const scope = getScope(PaneScope);
  const [, index] = (scope ?? ':0').split(':');

  const paneIndexAtom = atom<number>(parseInt(index));
  const creatingInstanceAtom = atom(false);
  const newlyAddedInstanceAtom = atom<Instance | null>(null);
  const newlyAddedNoteAtom = atom<Note | null>(null);
  const selectedStoryInstancesAtom = atom<string[]>([]);
  const mousePositionAtom = atom<MousePosition>(initialMousePosition);
  const showRightPanelAtom = atomWithStorage<boolean>(`sidepanel:story-${index}`, true);

  const currentEditingRef = scope ? useRef<string | null>(null) : undefined;

  return {
    usePaneIndexValue: () => useAtomValue(paneIndexAtom),
    useCreatingInstance: () => useAtom(creatingInstanceAtom),
    useNewlyAddedInstance: () => useAtom(newlyAddedInstanceAtom),
    useSelectedStoryInstances: () => useAtom(selectedStoryInstancesAtom),
    useNewlyAddedNote: () => useAtom(newlyAddedNoteAtom),
    useMousePosition: () => useAtom(mousePositionAtom),
    useShowRightPanel: () => useAtom(showRightPanelAtom),
    currentEditingRef,
    scope,
  };
});

export const useStoryPaneMolecule = () => useMolecule(storyPaneMolecule);
