import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Popper, TextField } from '@material-ui/core';

import { ReactComponent as Check } from 'assets/icons/systemicons/check.svg';
import { Box } from 'layouts/box/Box';
import transientOptions from 'theme/helpers';

export const SelectWrapper = styled('div')`
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  width: 100%;
`;

interface StyledOptionWrapperProps {
  $selected?: boolean;
}

export const StyledOption = styled(Box)`
  width: 100%;
  line-height: 36px;
  font-size: 12px;
`;

export const OptionCheck = styled(Check)`
  margin-top: 2px;
  path {
    fill-opacity: 0.9 !important;
  }
`;

export const StyledOptionWrapper = styled('div', transientOptions)<StyledOptionWrapperProps>`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  width: 100%;
  height: 36px;
  display: flex;
  ${({ $selected, theme }) =>
    $selected
      ? css`
          ${StyledOption} {
            color: ${theme.palette.dina.whiteHighEmphasis};
          }
          ${OptionCheck} {
            path {
              fill: ${theme.palette.dina.whiteHighEmphasis};
            }
          }
        `
      : ''};
`;

export const StyledTextField = styled(TextField)`
  svg path {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
  }

  .MuiFormHelperText-contained {
    margin: 2px 0 -2px 2px;
    color: ${({ theme }) => theme.palette.dina.statusWarning};
  }

  .MuiAutocomplete-inputRoot {
    margin-top: 4px;
    padding: 2px;
    width: 100%;
    display: inline-flex;
    flex-flow: row wrap;
    min-height: 24px;
    border: 1px solid ${({ theme }) => theme.palette.dina.inputBorderResting};
    border-radius: 4px;
    background-color: ${({ theme }) => theme.palette.dina.inputBackground};
    &:hover {
      background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
    }
    &.Mui-focused {
      ${({ theme }) => css`
        border-color: ${theme.palette.dina.statusOnFocused};
        background: ${theme.palette.dina.statusOnFocusedDiscreetFill};
      `}
    }
    &.Mui-error {
      border-color: ${({ theme }) => theme.palette.dina.warningBorder};
    }
  }
  .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] .MuiAutocomplete-endAdornment {
    right: 2px;
  }
  input {
    width: 256px;
    font-size: 14px;
    padding-left: 6px;
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
    padding: 4px 6px !important;
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: ${({ theme }) => theme.palette.dina.disabled};
    }
  }
  .MuiFilledInput-underline {
    &:hover:not($disabled):not($focused):not($error):before {
      border-bottom: none;
    }
    &:after {
      border-color: transparent;
    }
    &:before {
      border-color: transparent;
    }
  }
`;

export const StyledPopper = styled(Popper)`
  z-index: 3002;
  pointer-events: auto;

  .MuiAutocomplete-listbox {
    padding: 0;
  }
  .MuiAutocomplete-option {
    background: ${({ theme }) => theme.palette.dina.backgroundHover};
    height: 32px;
    &:hover,
    &[data-focus='true'] {
      background: rgba(255, 255, 255, 0.05);
      .styled-option {
        border: none;
      }
    }
  }

  .MuiAutocomplete-option:last-child .styled-option {
    border: none;
  }
  .MuiAutocomplete-noOptions {
    ${({ theme }) => theme.typography.dina.captionMedium};
  }
  .MuiAutocomplete-listbox {
    li {
      padding: 8px !important;
    }

    li[aria-selected='true'] {
      background: ${({ theme }) => theme.palette.dina.onSelected};
      color: white;
    }
  }
`;
