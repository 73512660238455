/* eslint-disable @typescript-eslint/no-use-before-define */
import { ReactNode, useContext, useMemo } from 'react';
import { Image, Link, Text, View } from '@react-pdf/renderer';
import format from 'date-fns/format';
import { Dictionary, keyBy } from 'lodash';
import { v4 as uuidV4 } from 'uuid';

import { BlockWithLabelAndMdf } from 'api/mdfBlocks/types';
import defaultThumbnail from 'assets/images/default/defaultThumbnail.png';
import getCleanLink from 'components/editor/utils/getCleanLink';
import UserContext from 'contexts/UserContext';
import { getSubMdf, hasPermission, shouldFilterField } from 'features/mdf/mdf-utils';
import useGetMembersInfo from 'hooks/useGetMembersInfo';
import useStorageImage from 'hooks/useStorageImage';
import { CustomData, CustomElement, CustomText, EditorValue, isCustomElement, User } from 'types';
import { Metadata, OrderWithMdf } from 'types/forms/forms';
import {
  FieldTypeEnum,
  LayoutSettings,
  Mdf,
  MdfField,
  MemberType,
  MemberTypeEnum,
} from 'types/graphqlTypes';
import getMembersFromRelationField from 'utils/mdf/getMembersFromRelationField';
import { getThumbnailKey } from 'utils/mediaKey';
import getDirection from 'utils/text/getDirection';

import AccessoryIcon from '../components/AccessoryIcon';
import AdlibIcon from '../components/AdlibIcon';
import AudioIcon from '../components/AudioIcon';
import BreakIcon from '../components/BreakIcon';
import CameraIcon from '../components/CameraIcon';
import CGIcon from '../components/CGIcon';
import CheckboxOff from '../components/CheckboxOff';
import CheckboxOn from '../components/CheckboxOn';
import DveIcon from '../components/DveIcon';
import GraphicsIcon from '../components/GraphicsIcon';
import JingleIcon from '../components/JingleIcon';
import LiveIcon from '../components/LiveIcon';
import TelephoneIcon from '../components/TelephoneIcon';
import VideoClipIcon from '../components/VideoClipIcon';
import VoiceOverIcon from '../components/VoiceOverIcon';
import repositionTrailingDotForRTL from '../utils/repositionTrailingDotForRTL';

import { styles } from './styles';

const getHeaderFontSize = (type: string) => {
  switch (type) {
    case 'heading-one':
      return 34;
    case 'heading-two':
      return 30;
    case 'heading-three':
      return 24;
    case 'heading-four':
      return 20;
    case 'heading-five':
      return 18;
    case 'heading-six':
      return 16;
    default:
      return 16;
  }
};

const typeToIconMap = {
  dve: <DveIcon />,
  jingle: <JingleIcon />,
  break: <BreakIcon />,
  adlib: <AdlibIcon />,
  telephone: <TelephoneIcon />,
  audio: <AudioIcon />,
  accessory: <AccessoryIcon />,
};

const getNodeStyles = (node: CustomText) => {
  const { bold, color, underline, strikeThrough, italic } = node;
  const nodeStyles: Record<string, unknown>[] = [];

  if (bold) nodeStyles.push(styles.bold);

  if (italic) nodeStyles.push(styles.italic);

  if (color) nodeStyles.push({ color: color === '#fff' || color === '#ffffff' ? '#000' : color });

  if (underline && !strikeThrough) nodeStyles.push(styles.underline);

  if (strikeThrough && !underline) nodeStyles.push(styles.strikeThrough);

  if (underline && strikeThrough) nodeStyles.push(styles.underlineAndST);

  return nodeStyles;
};

const scriptDeterminantColors = ['#fff', '#ffffff', '#000', '#000000'];
export const getScriptFromContent = (content?: EditorValue | null) => {
  if (!content) return;

  return content.document.reduce((acc, doc) => {
    const { type, children } = doc;
    if (type === 'paragraph' && !!children) {
      children.forEach((child) => {
        if (isCustomElement(child)) return;
        const { color } = child;

        if ((!color || scriptDeterminantColors.includes(color)) && !!child.text) acc.push(child);
      });
    }
    return acc;
  }, [] as CustomText[]);
};

export const ImageComponent = ({ data, type }: { data: CustomData; type: string }) => {
  const { mId, mRefId, src, proxy, mTitle, title, mediaType } = data;
  const key = (src || getThumbnailKey(mId, mRefId)) ?? undefined;
  const {
    data: thumbnailData,
    error: storageError,
    loading: storageLoading,
  } = useStorageImage(key);
  const s3ThumbUrl = storageError && !storageLoading ? defaultThumbnail : thumbnailData;
  const imgSrc = s3ThumbUrl ?? proxy;

  if (!imgSrc) return <Text>{type}</Text>;

  return (
    <View key={imgSrc}>
      <Image src={imgSrc} style={styles.image} />
      <Text style={styles.caption}>
        Title: {mTitle ?? title ?? ''} {mediaType ? `Type: ${mediaType}` : ''}
      </Text>
    </View>
  );
};

const ListItem = ({
  value = '',
  type = 'unordered-list',
  order,
  style = {},
}: {
  value: string | CustomElement;
  type: string;
  order: number;
  style?: Record<string, unknown> | Record<string, unknown>[];
}) => {
  const direction = typeof value === 'string' ? getDirection(value) : 'ltr';

  return (
    <View
      style={
        direction === 'rtl'
          ? { flexDirection: 'row-reverse', alignItems: 'flex-end' }
          : { flexDirection: 'row' }
      }
      key={uuidV4()}
    >
      <View style={styles.bullet}>
        {type === 'unordered-list' ? (
          <Text>{`${direction === 'rtl' ? ' \u2022' : '\u2022 '}`}</Text>
        ) : (
          <Text>{`${direction === 'rtl' ? ` .${order}` : `${order}. `}`}</Text>
        )}
      </View>
      {typeof value === 'string' ? (
        <Text style={style}>{value}</Text>
      ) : (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        getDocumentComponent({ doc: value })
      )}
    </View>
  );
};

const emptyUserMeta = '{"email": "", "phone": ""}';

const MemberField = ({ member }: { member: MemberType | undefined }) => {
  let memberMeta: Metadata = {
    email: '',
    phone: '',
  };

  try {
    memberMeta = JSON.parse(member?.metadata ?? emptyUserMeta) as Metadata;
  } catch (e) {
    //
  }

  return (
    <View style={[styles.fieldWrapper, { border: '1px solid #000', borderRadius: 4 }]}>
      <Text style={styles.fieldLabel}>Name</Text>
      <Text style={styles.fieldContent}>
        {member?.mTitle ?? ''} [{member?.mType?.toUpperCase()}]
      </Text>
      <View style={styles.horizontalLine} />

      <Text style={styles.fieldLabel}>Email</Text>
      <Text style={styles.fieldContent}>{(memberMeta?.email as string | undefined) ?? ''}</Text>
      <View style={styles.horizontalLine} />

      <Text style={styles.fieldLabel}>Phone</Text>
      <Text style={styles.fieldContent}>{(memberMeta?.phone as string | undefined) ?? ''}</Text>
      <View style={styles.horizontalLine} />
    </View>
  );
};

export const getOrderStatusString = (status: string) => {
  if (status === 'closed') return 'Completed';
  if (status === 'in_progress') return 'In progress';
  if (status === 'created') return 'Created';

  return status;
};

export const getMdfFieldComponent = ({
  field,
  settingsMap,
  metadata,
  showBottomDivider,
  getMember,
  getMemberTitle,
  relationMembers = [],
  contacts = [],
}: {
  field: MdfField;
  settingsMap: Dictionary<LayoutSettings>;
  metadata: Metadata;
  showBottomDivider: boolean;
  getMemberTitle: (userId: string) => string | undefined;
  getMember: (userId: string) => User | undefined;
  relationMembers?: MemberType[];
  contacts?: MemberType[];
}) => {
  const label = settingsMap[field.fieldId].label;
  const fieldValue = metadata[field.fieldId]?.toString() ?? '';

  switch (field.type) {
    case FieldTypeEnum.text:
    case FieldTypeEnum.number:
      return (
        <View key={field.fieldId} style={styles.fieldWrapper}>
          <Text style={styles.fieldLabel}>{label}</Text>
          <Text style={styles.fieldContent}>{fieldValue}</Text>
          {showBottomDivider && <View style={styles.horizontalLine} />}
        </View>
      );

    case FieldTypeEnum.link:
      return (
        <View key={field.fieldId} style={styles.fieldWrapper}>
          <Text style={styles.fieldLabel}>{label}</Text>
          <Link src={getCleanLink(fieldValue)}>
            <Text style={[styles.fieldContent, { width: 'auto' }]}>{fieldValue}</Text>
          </Link>
          {showBottomDivider && <View style={styles.horizontalLine} />}
        </View>
      );

    case FieldTypeEnum.user: {
      const title = getMemberTitle(fieldValue);
      const user = getMember(fieldValue) ?? contacts.find((contact) => contact.mId === fieldValue);

      return (
        <View key={field.fieldId} style={styles.fieldWrapper}>
          <Text style={styles.fieldLabel}>{label}</Text>
          {user ? (
            <View style={{ padding: 8 }}>
              <MemberField member={user} />
            </View>
          ) : title ? (
            <Text style={styles.fieldContent}>{title ?? ''}</Text>
          ) : (
            <Text style={styles.fieldContent}>{fieldValue}</Text>
          )}
          {showBottomDivider && <View style={styles.horizontalLine} />}
        </View>
      );
    }

    case FieldTypeEnum.treechoice:
      return (
        <View key={field.fieldId} style={styles.fieldWrapper}>
          <Text style={styles.fieldLabel}>{label}</Text>
          <Text style={[styles.fieldContent, { alignItems: 'center' }]}>
            {fieldValue.replace(/,/g, ' -> ')}
          </Text>
          {showBottomDivider && <View style={styles.horizontalLine} />}
        </View>
      );

    case FieldTypeEnum.checkbox: {
      const value = fieldValue === 'true' ? `[x] ${label}` : `[ ] ${label}`;

      return (
        <View key={field.fieldId} style={styles.fieldWrapper}>
          <Text style={styles.fieldLabel}>{label}</Text>
          <Text style={[styles.fieldContent, { alignItems: 'center' }]}>{value}</Text>
          {showBottomDivider && <View style={styles.horizontalLine} />}
        </View>
      );
    }

    case FieldTypeEnum.date: {
      return (
        <View key={field.fieldId} style={styles.fieldWrapper}>
          <Text style={styles.fieldLabel}>{label}</Text>
          {fieldValue !== '' ? (
            <Text style={styles.fieldContent}>
              {format(new Date(fieldValue), 'MMM D YYYY, HH:mm:ss (Z)')}
            </Text>
          ) : (
            <View style={styles.fieldContent} />
          )}
          {showBottomDivider && <View style={styles.horizontalLine} />}
        </View>
      );
    }

    case FieldTypeEnum.multiplechoice: {
      const alternetLabelMap = new Map<string, string>();

      fieldValue.split(',').forEach((fValue) => {
        const alternative = field.alternatives?.find((alt) => alt.value === fValue);
        if (!alternetLabelMap.has(fValue)) {
          alternetLabelMap.set(fValue, alternative?.label ?? fValue);
        }
      });

      return (
        <View key={field.fieldId} style={styles.fieldWrapper}>
          <Text style={styles.fieldLabel}>{label}</Text>
          <View style={[styles.fieldContent, { flexDirection: 'row', gap: 6 }]}>
            {fieldValue !== '' ? (
              fieldValue.split(',').map((value) => (
                <View key={uuidV4()} style={styles.chip}>
                  <Text>{alternetLabelMap.get(value) ?? value}</Text>
                </View>
              ))
            ) : (
              <View />
            )}
          </View>
          {showBottomDivider && <View style={styles.horizontalLine} />}
        </View>
      );
    }

    case FieldTypeEnum.choice: {
      const alternateLabel = field.alternatives?.find((alt) => alt.value === fieldValue)?.label;

      return (
        <View key={field.fieldId} style={styles.fieldWrapper}>
          <Text style={styles.fieldLabel}>{label}</Text>
          <View style={[styles.fieldContent, { flexDirection: 'row' }]}>
            {fieldValue !== '' ? (
              <View style={styles.chip}>
                <Text>{alternateLabel ?? fieldValue}</Text>
              </View>
            ) : (
              <View />
            )}
          </View>

          {showBottomDivider && <View style={styles.horizontalLine} />}
        </View>
      );
    }

    case FieldTypeEnum.relation: {
      const actualFieldValue = metadata[field.fieldId];

      const fieldMembers = getMembersFromRelationField(actualFieldValue, relationMembers);

      return (
        <View key={field.fieldId} style={styles.fieldWrapper}>
          <Text style={styles.fieldLabel}>{label}</Text>
          <View
            style={[
              styles.fieldContent,
              { flexDirection: 'column', alignItems: 'flex-start', gap: '4pt' },
            ]}
          >
            {fieldMembers.length !== 0 ? (
              fieldMembers.map((fMember) => {
                if (
                  [
                    MemberTypeEnum.Contact,
                    MemberTypeEnum.User,
                    MemberTypeEnum.Department,
                    MemberTypeEnum.Team,
                  ].includes(fMember?.mType as MemberTypeEnum)
                ) {
                  const member =
                    getMember(fMember?.mId as string) ??
                    contacts.find((contact) => contact.mId === fMember?.mId);

                  return <MemberField key={member?.mRefId} member={member} />;
                }

                return (
                  <View style={styles.chip} key={uuidV4()}>
                    <Text>{`${fMember?.mTitle ?? 'Untitled'} ${
                      fMember?.mType ? `(${fMember?.mType?.toUpperCase()})` : ''
                    }`}</Text>
                  </View>
                );
              })
            ) : (
              <View />
            )}
          </View>

          {showBottomDivider && <View style={styles.horizontalLine} />}
        </View>
      );
    }

    default:
      return (
        <View key={field.fieldId} style={styles.fieldWrapper}>
          <Text style={styles.fieldLabel}>{label}</Text>
          <Text style={styles.fieldContent}>{metadata[field.fieldId]?.toString()}</Text>
          {showBottomDivider && <View style={styles.horizontalLine} />}
        </View>
      );
  }
};

export const Order = ({
  order,
  groups,
  getMemberTitle,
  getMember,
  oddIndex,
  subMdfs,
  subOrders,
  relationMembers,
  contacts,
}: {
  order: OrderWithMdf;
  getMemberTitle: (userId: string) => string | undefined;
  getMember: (userId: string) => User | undefined;
  groups: string[];
  oddIndex: boolean;
  subMdfs?: Mdf[];
  subOrders?: OrderWithMdf[];
  relationMembers?: MemberType[];
  contacts?: MemberType[];
}) => {
  const { metadata, mdf } = order;
  const subTypes = keyBy(subMdfs, (subMdf) => subMdf.label);

  const settingsMap = keyBy(mdf.views.default, (setting) => setting.fieldId);

  const visibleFields = mdf.fields?.filter((f) =>
    shouldFilterField(
      f,
      settingsMap,
      settingsMap,
      true,
      hasPermission(mdf?.permissions?.read[f.fieldId], groups),
    ),
  );

  const backgroundColor = useMemo(() => {
    if (order.mStatus === 'closed') return '#E4F5E3';
    if (order.mStatus === 'in_progress') return '#FFF5BA';
    return oddIndex ? '#eee' : '#ddd';
  }, [oddIndex, order.mStatus]);

  const createdByUser = getMember(order.mCreatedById);
  const assigneeUser = getMember(order.mAssignee ?? '');

  return (
    <View style={{ padding: 4, display: 'flex', gap: 4, flexDirection: 'column' }}>
      <View
        style={{
          borderRadius: 4,
          backgroundColor,
        }}
      >
        <View style={styles.fieldWrapper}>
          <Text style={styles.fieldLabel}>Created By</Text>
          <View style={{ padding: 8 }}>
            <MemberField member={createdByUser} />
          </View>
          <View style={styles.horizontalLine} />
        </View>
        <View style={styles.fieldWrapper}>
          <Text style={styles.fieldLabel}>Assignee</Text>
          <View style={{ padding: 8 }}>
            {assigneeUser ? <MemberField member={assigneeUser} /> : <Text>No assignee</Text>}
          </View>
          <View style={styles.horizontalLine} />
        </View>
        <View style={styles.fieldWrapper}>
          <Text style={styles.fieldLabel}>Status</Text>
          <Text style={styles.fieldContent}>{getOrderStatusString(order.mStatus)}</Text>
          <View style={styles.horizontalLine} />
        </View>
        {visibleFields?.map((field, index) => {
          if (field.type === FieldTypeEnum.subtype) {
            const subMdf = getSubMdf(field, metadata, subTypes);

            return (
              <View key={field.fieldId} style={{ margin: '8px' }}>
                <Block
                  fields={subMdf?.fields}
                  layoutSettings={subMdf?.views.default}
                  permissions={subMdf?.permissions}
                  metadata={metadata}
                  blockTitle={settingsMap[field.fieldId].label}
                  subMdfs={subMdfs}
                  relationMembers={relationMembers}
                  contacts={contacts}
                />
              </View>
            );
          }

          return getMdfFieldComponent({
            field,
            settingsMap,
            metadata,
            showBottomDivider: index !== visibleFields.length - 1,
            getMemberTitle,
            getMember,
            relationMembers,
            contacts,
          });
        })}

        {subOrders?.length && (
          <View style={{ padding: 8 }}>
            <View style={{ border: '1px solid #000', borderRadius: 4 }}>
              <View style={[styles.header, { padding: '4px 8px 0', marginBottom: 0 }]}>
                <Text style={[styles.title, { fontSize: 14 }]}>Sub Tasks</Text>
              </View>
              <View style={styles.horizontalLine} />

              {subOrders.map((subOrder, index) => {
                return (
                  <Order
                    key={subOrder.mId}
                    order={subOrder}
                    getMemberTitle={getMemberTitle}
                    groups={groups}
                    oddIndex={index % 2 !== 0}
                    getMember={getMember}
                    subMdfs={subMdfs}
                    relationMembers={relationMembers}
                    contacts={contacts}
                  />
                );
              })}
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

interface BlockProps {
  metadata: Metadata;
  orders?: OrderWithMdf[];
  subMdfs?: Mdf[];
  fields?: MdfField[];
  layoutSettings?: LayoutSettings[];
  permissions?: Mdf['permissions'];
  blockTitle?: string;
  subOrders?: OrderWithMdf[];
  color?: string;
  relationMembers?: MemberType[];
  contacts?: MemberType[];
}

export const Block = ({
  fields,
  layoutSettings,
  metadata,
  permissions,
  subMdfs,
  blockTitle,
  orders,
  subOrders,
  color,
  relationMembers,
  contacts,
}: BlockProps) => {
  const { groups } = useContext(UserContext);
  const { getMemberTitle, getMember } = useGetMembersInfo();

  const subTypes = keyBy(subMdfs, (mdf) => mdf.label);

  const settingsMap = keyBy(layoutSettings, (setting) => setting.fieldId);

  const visibleFields = fields?.filter((f) =>
    shouldFilterField(
      f,
      settingsMap,
      settingsMap,
      true,
      hasPermission(permissions?.read[f.fieldId] as string[], groups),
    ),
  );

  return (
    <View style={styles.blockWrapper}>
      <View style={color ? { ...styles.colorLabel, backgroundColor: color } : styles.colorLabel} />
      <View style={{ flex: 1, height: '100%', flexDirection: 'column' }}>
        <View style={[styles.header, { padding: '4px 8px 0', marginBottom: 0 }]}>
          <Text style={[styles.title, { fontSize: 14 }]}>{blockTitle}</Text>
        </View>
        <View style={styles.horizontalLine} />

        {visibleFields ? (
          visibleFields?.map((field, index) => {
            if (field.type === FieldTypeEnum.subtype) {
              const subMdf = getSubMdf(field, metadata, subTypes);

              return (
                <View key={field.fieldId} style={{ margin: '8px' }}>
                  <Block
                    fields={subMdf?.fields}
                    layoutSettings={subMdf?.views.default}
                    permissions={subMdf?.permissions}
                    metadata={metadata}
                    blockTitle={settingsMap[field.fieldId].label}
                    relationMembers={relationMembers}
                    contacts={contacts}
                  />
                </View>
              );
            }

            return getMdfFieldComponent({
              field,
              settingsMap,
              metadata,
              showBottomDivider: index !== visibleFields.length - 1,
              getMemberTitle,
              getMember,
              relationMembers,
              contacts,
            });
          })
        ) : (
          <View style={styles.emptyBlock}>
            <Text>No options selected</Text>
          </View>
        )}

        {orders?.length && (
          <View style={{ padding: 8 }}>
            <View style={{ border: '1px solid #000', borderRadius: 4 }}>
              <View style={[styles.header, { padding: '4px 8px 0', marginBottom: 0 }]}>
                <Text style={[styles.title, { fontSize: 14 }]}>Tasks</Text>
              </View>
              <View style={styles.horizontalLine} />

              {orders.map((order, index) => {
                return (
                  <Order
                    key={order.mId}
                    order={order}
                    getMemberTitle={getMemberTitle}
                    groups={groups}
                    oddIndex={index % 2 !== 0}
                    subOrders={subOrders?.filter((subOrder) => subOrder.mResourceId === order.mId)}
                    getMember={getMember}
                    subMdfs={subMdfs}
                    relationMembers={relationMembers}
                    contacts={contacts}
                  />
                );
              })}
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

export const getDocumentComponent = (
  options:
    | {
        doc: CustomElement;
      }
    | {
        doc: CustomElement;
        blocks: BlockWithLabelAndMdf[];
        orders: OrderWithMdf[];
        mdfsSeparated: {
          defaults: Mdf[];
          custom: Mdf[];
          subTypes: Mdf[];
          instances: Mdf[];
        };
        relationMembers?: MemberType[];
        contacts?: MemberType[];
      },
): ReactNode => {
  const { doc } = options;
  const { data, type, children } = doc;

  switch (type) {
    case 'paragraph': {
      let direction = 'ltr';

      const childrenView = children.map((child, index) => {
        if (isCustomElement(child)) return getDocumentComponent({ doc: child });
        const { text } = child;
        const childStyles = getNodeStyles(child);

        if (index === 0) {
          // determine to render as rtl if there's an arabic word at the begining of the sentence
          direction = getDirection(text);
        }

        const filteredStyleForArabic =
          direction === 'rtl'
            ? childStyles.filter((cStyle) => cStyle.fontStyle !== 'italic')
            : childStyles;

        const modifiedText = repositionTrailingDotForRTL(text);

        return (
          <Text
            key={uuidV4()}
            style={[...filteredStyleForArabic, { flex: '0 0 auto', flexWrap: 'wrap' }]}
          >
            {modifiedText}
          </Text>
        );
      });

      return (
        <View
          key={uuidV4()}
          style={[
            direction === 'rtl'
              ? { flexDirection: 'row-reverse', alignItems: 'flex-end', gap: 4 }
              : { flexDirection: 'row', gap: 4 },
            { textAlign: direction === 'rtl' ? 'right' : 'left', flexWrap: 'wrap' },
          ]}
        >
          {childrenView}
        </View>
      );
    }

    case 'heading-one':
    case 'heading-two':
    case 'heading-three':
    case 'heading-four':
    case 'heading-five':
    case 'heading-six':
      return children.map((child) => {
        if (isCustomElement(child)) return;
        const childStyles = getNodeStyles(child);
        const headerStyles = {
          ...childStyles,
          fontSize: getHeaderFontSize(type),
          fontWeight: 600,
        };

        return (
          <Text key={uuidV4()} style={headerStyles}>
            {child.text}
          </Text>
        );
      });

    case 'ordered-list':
    case 'unordered-list':
      return (
        <View style={{ marginTop: 8 }} key={uuidV4()}>
          {children.map((child, index) => {
            if (!isCustomElement(child)) return;
            let listChildren = child.children;
            if (listChildren.length > 1) {
              listChildren = listChildren.filter((ch) => isCustomElement(ch));
            }

            return listChildren.map((grandChild) => {
              const childStyles = 'text' in grandChild ? getNodeStyles(grandChild) : undefined;
              return (
                <ListItem
                  value={'text' in grandChild ? grandChild.text : grandChild}
                  type={type}
                  order={index + 1}
                  style={childStyles}
                  key={uuidV4()}
                />
              );
            });
          })}
        </View>
      );

    case 'block-quote': {
      const text = `"${(children[0] as CustomText).text}"`;
      const direction = getDirection(text);

      return (
        <Text
          key={uuidV4()}
          style={direction === 'rtl' ? { color: 'gray', textAlign: 'right' } : styles.blockQuote}
        >
          {text}
        </Text>
      );
    }

    case 'checklist': {
      let direction: string = 'ltr';

      const childText = children.map((child, index) => {
        if (isCustomElement(child)) return;
        const { text } = child;
        const childStyles = getNodeStyles({
          ...child,
          strikeThrough: data?.checked,
        });

        if (index === 0) {
          direction = getDirection(text);
        }

        return (
          <Text key={uuidV4()} style={childStyles}>
            {child.text}
          </Text>
        );
      });

      return (
        <View
          style={{
            flexDirection: direction === 'rtl' ? 'row-reverse' : 'row',
            alignItems: 'center',
            marginTop: '4px',
            marginLeft: '8px',
          }}
          key={uuidV4()}
        >
          <View style={styles.checkbox}>{data?.checked ? <CheckboxOn /> : <CheckboxOff />}</View>
          {childText}
        </View>
      );
    }

    case 'link':
      return (
        <Link src={getCleanLink(data?.href as string)} key={uuidV4()}>
          {children.map((child) => {
            return <Text key={uuidV4()}>{(child as CustomText).text}</Text>;
          })}
        </Link>
      );

    case 'package':
    case 'voiceOver':
      return (
        <View style={[styles.column, styles.package]} key={uuidV4()}>
          <View style={[styles.row, styles.primaryItems]}>
            {type.toLowerCase() === 'package' ? <VideoClipIcon /> : <VoiceOverIcon />}
            <Text>{`${data?.templateType} - ${data?.templateVariant}`}</Text>
          </View>
          {data?.assets?.map((asset) => (
            <Text key={uuidV4()} style={{ paddingLeft: '40px', fontSize: '14px' }}>
              {asset?.title ?? ''}
            </Text>
          ))}
        </View>
      );

    case 'camera':
      return (
        <View style={[styles.row, styles.primaryItems]} key={uuidV4()}>
          <CameraIcon />
          <Text style={styles.camera}>{`${data?.templateType} - ${data?.templateVariant}`}</Text>
        </View>
      );

    case 'live':
      return (
        <View style={[styles.row, styles.primaryItems]} key={uuidV4()}>
          <LiveIcon />
          <Text style={styles.live}>{`${data?.templateType} - ${data?.templateVariant}`}</Text>
        </View>
      );

    case 'overlayGraphics':
    case 'fullscreenGraphics':
      return (
        <View style={[styles.row, styles.primaryItems]} key={uuidV4()}>
          {type === 'fullscreenGraphics' ? <GraphicsIcon /> : <CGIcon />}
          <Text style={styles.graphics}>{`${data?.templateType} - ${data?.templateVariant}`}</Text>
        </View>
      );

    case 'mention': {
      return (
        <View key={uuidV4()}>
          <Text style={styles.mention}>{`@${data?.mTitle}`}</Text>;
        </View>
      );
    }

    case 'horizontal-rule':
      return <View key={uuidV4()} style={styles.horizontalLine} />;

    case 'mdf-block': {
      if ('blocks' in options && 'orders' in options && 'mdfsSeparated' in options) {
        const { blocks, orders, mdfsSeparated, relationMembers, contacts } = options;
        if (!blocks || !orders)
          return (
            <View style={[styles.row, styles.primaryItems]} key={uuidV4()}>
              {typeToIconMap[type as keyof typeof typeToIconMap] ?? null}
              <Text>{type}</Text>
            </View>
          );

        const block = blocks?.find((blk) => blk.mRefId === data?.mId);
        const ordersForBlock = orders?.filter((order) => order.mResourceId === block?.mRefId);

        if (!block)
          return (
            <View style={[styles.row, styles.primaryItems]} key={uuidV4()}>
              <Text>Could not find item</Text>
            </View>
          );

        const { mTitle, mRefId, mdf, metadata, color } = block;

        return (
          <View key={uuidV4()} style={styles.paddingVertical}>
            <Block
              key={mRefId}
              metadata={metadata}
              fields={mdf?.fields}
              layoutSettings={mdf?.views.default}
              permissions={mdf?.permissions}
              blockTitle={mTitle}
              orders={ordersForBlock}
              subMdfs={mdfsSeparated.subTypes}
              color={color}
              relationMembers={relationMembers}
              contacts={contacts}
            />
          </View>
        );
      }
      return null;
    }

    default:
      return (
        <View style={[styles.row, styles.primaryItems]} key={uuidV4()}>
          {typeToIconMap[type as keyof typeof typeToIconMap] ?? null}
          <Text>{type}</Text>
        </View>
      );
  }
};
