import { ChangeEvent, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useContextMenu } from 'react-contexify';
import { InputAdornment } from '@material-ui/core';
import { useAtom } from 'jotai';

import { ReactComponent as CopyIcon } from 'assets/icons/systemicons/copy.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/systemicons/print.svg';
import { IconButton } from 'components/buttons';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import { useEditorMolecule } from 'components/editor/store';
import useDeleteOrder from 'components/orderFormDialog/api/useDeleteOrder';
import useToast from 'components/toast/useToast';
import Tooltip from 'components/tooltip/Tooltip';
import useCommentSubscription from 'features/comments/hooks/useCommentSubscription';
import ContextMenu from 'features/contextMenu';
import TasksPrint from 'features/print/TasksPrint';
import { SearchField } from 'features/search/styled';
import { useSplitViewMolecule } from 'features/splitView/store';
import useCopyTasks from 'hooks/useCopyTasks';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import useFuseSearch from 'hooks/useFuseSearch';
import useInputEvents from 'hooks/useInputEvents';
import { ResourceDetails } from 'hooks/useResourceDetails';
import { useGetOrders } from 'screens/space/api/useGetOrdersAndForms';
import { useOrderFormFilter } from 'store';
import { GetOrderEnum, TaskStatusEnum } from 'types/graphqlTypes';
import { atomWithSessionStorage } from 'utils/atoms/atomWithSessionStorage';
import preventDefaultAndPropagation from 'utils/preventDefaultAndStopPropagation';

import {
  Fallback,
  getSupportedResourceType,
  OrderWithLabel,
  TASK_MENU_ID,
  TaskItem,
} from './ComponentUtils';
import { getStatusOptions } from './EditOrderBlock';
import { ToggleList } from './ToggleList';

import { CloseIcon, FilterWrapper, PlusIcon, SearchIcon, SearchWrapper } from './styled';

const titleSearchAtom = atomWithSessionStorage<string>('task:titleSearch', '');

interface ControlledTaskItemProps {
  order: OrderWithLabel;
  updatePane?: () => void;
}

function ControlledTaskItem({ order, updatePane }: Readonly<ControlledTaskItemProps>) {
  const [open, setOpen] = useState(false);
  const { useSelectedBlockId, useShowComment, useForceOpenId } = useSplitViewMolecule();
  const [selectedBlockId, setSelectedBlockId] = useSelectedBlockId();
  const [forceOpenId, setForceOpenId] = useForceOpenId();
  const [showComment] = useShowComment();
  const { show } = useContextMenu({ id: TASK_MENU_ID });

  const onContextMenu = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    show({
      event,
      props: {
        order: order,
      },
    });
  }, []);

  useEffect(() => {
    if (forceOpenId === order.mId) {
      setOpen(true);
      setForceOpenId(undefined);
    }
  }, [forceOpenId, setForceOpenId]);

  const onSelect = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      preventDefaultAndPropagation(event);
      setOpen((prev) => !prev);
      if (selectedBlockId !== order.mId) {
        setSelectedBlockId(order.mId);
        setForceOpenId(undefined);
      }
    },
    [selectedBlockId, order.mId],
  );

  return (
    <TaskItem
      id={`toggle-${order.mId}`}
      onContextMenu={onContextMenu}
      order={order}
      open={open}
      onSelect={onSelect}
      selected={selectedBlockId === order?.mId}
      showComment={selectedBlockId === order?.mId && showComment}
      updatePane={updatePane}
    />
  );
}

type MenuClickType = {
  id: string;
  props: {
    order: OrderWithLabel;
  };
};
interface TaskProps {
  resourceDetails: ResourceDetails;
  queryType?: GetOrderEnum;
  updatePane?: () => void;
}

function Task({
  resourceDetails,
  queryType = GetOrderEnum.Resource,
  updatePane,
}: Readonly<TaskProps>) {
  const { show } = useContextMenu({ id: 'orderForms' });
  const { errorToast } = useToast();
  const [itemToDelete, setItemToDelete] = useState<OrderWithLabel | null>(null);
  const [, setFormFilter] = useOrderFormFilter();
  const { deleteOrder } = useDeleteOrder();
  const { resource, orderFormMap } = resourceDetails ?? {};
  const { orders } = useGetOrders(resource.mId, queryType, TaskStatusEnum.all);
  const [searchText, setSearchText] = useAtom(titleSearchAtom);
  const [localValue, setLocalValue] = useState(searchText);
  const fuseSearch = useFuseSearch();
  const { usePrintTasks } = useEditorMolecule();
  const [printTasks, setPrintTasks] = usePrintTasks();

  useCommentSubscription([resource.mId]);

  const matchFunction = useCallback(
    (list: OrderWithLabel[]) =>
      fuseSearch(list, ['formLabel', 'statusLabel'], localValue?.substring(1) || ''),
    [fuseSearch, localValue],
  );

  const orderWithLabel: OrderWithLabel[] = useMemo(() => {
    return orders.map((order) => {
      const stsOptions = getStatusOptions(order.mFormId, orderFormMap);
      const orderForm = orderFormMap?.[order.mFormId];
      return {
        ...order,
        formLabel: orderForm?.mDescription ?? '',
        formColor: orderForm?.mColor,
        statusLabel: stsOptions.find((opt) => opt.value === order.mStatus)?.label ?? order.mStatus,
        statusOptions: stsOptions,
      };
    });
  }, [orders, orderFormMap]);

  const filteredOrders = useMemo(
    () => matchFunction(orderWithLabel).sort((a, b) => a.formLabel.localeCompare(b.formLabel)),
    [matchFunction, orderWithLabel],
  );

  const { onCopy, loading } = useCopyTasks(filteredOrders);

  const [inputRef, handleKeydown, handleBlur] = useInputEvents(
    setSearchText,
    localValue,
    searchText,
  );

  const [debouncedSetText] = useDebouncedCallback(setSearchText, 300);

  const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    event.preventDefault();
    const inputValue = event.target.value;
    void debouncedSetText(inputValue);
    setLocalValue(inputValue);
  }, []);

  const clearSearch: MouseEventHandler<SVGSVGElement> = useCallback(
    (event) => {
      event.preventDefault();
      void debouncedSetText('');
      setLocalValue('');
    },
    [setLocalValue],
  );

  const onCloseTasksPrint = useCallback(() => {
    setPrintTasks(false);
  }, [setPrintTasks]);

  const openContextMenu: MouseEventHandler<SVGSVGElement> = useCallback(
    (event) => {
      event.preventDefault();
      setFormFilter({
        types: getSupportedResourceType(resource),
      });
      show({
        event,
        props: {
          resourceId: resource.mId,
          resourceType: resource.mType,
          mStoryId: resource.mStoryId,
        },
      });
    },
    [setFormFilter, show, resource],
  );

  const deleteTask = useCallback(async () => {
    if (itemToDelete) {
      deleteOrder({ mId: itemToDelete.mId, mResourceId: itemToDelete.mResourceId })
        .catch(errorToast)
        .finally(() => {
          setItemToDelete(null);
        });
    }
  }, [itemToDelete]);

  const onMenuClick = useCallback(
    (data: MenuClickType) => {
      if (data.id === 'removeTask') setItemToDelete(data.props.order);
    },
    [setItemToDelete],
  );

  return (
    <>
      <ToggleList
        header={
          <SearchWrapper container height="48px" width="100%">
            <FilterWrapper container height="48px" width="36px">
              <Tooltip title="Add new task">
                <PlusIcon className="skipOverride" onClick={openContextMenu} />
              </Tooltip>
            </FilterWrapper>
            <SearchField
              id="sidepanel-search-input"
              size="small"
              value={localValue}
              onChange={handleChange}
              fullWidth
              InputProps={{
                placeholder: 'Type to find task...',
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className="skipOverride" />
                  </InputAdornment>
                ),
                endAdornment: localValue && (
                  <InputAdornment position="end" style={{ marginRight: '0px' }}>
                    <CloseIcon className="skipOverride" onMouseDown={clearSearch} />
                  </InputAdornment>
                ),
              }}
              onKeyDown={handleKeydown}
              onBlur={handleBlur}
              inputRef={inputRef}
              variant="outlined"
            />
            <IconButton
              variant="contained"
              usage="contained"
              size={24}
              iconSize={26}
              title="Print"
              onClick={() => {
                setPrintTasks(true);
              }}
              disableEnhancedIconOpacity
            >
              <PrintIcon />
            </IconButton>
            <IconButton
              variant="contained"
              usage="contained"
              title="Copy planning items to clipboard"
              size={24}
              iconSize={24}
              disabled={loading}
              onClick={onCopy}
              disableEnhancedIconOpacity
            >
              <CopyIcon />
            </IconButton>
          </SearchWrapper>
        }
        list={
          <>
            {filteredOrders.length > 0 ? (
              filteredOrders.map((order) => (
                <ControlledTaskItem key={order.mId} order={order} updatePane={updatePane} />
              ))
            ) : (
              <Fallback label="No task items..." />
            )}
          </>
        }
      />
      <ContextMenu
        id={TASK_MENU_ID}
        menuItems={[
          {
            id: 'removeTask',
            label: 'Delete task',
          },
        ]}
        onClick={onMenuClick}
      />
      {printTasks && (
        <TasksPrint isDialogOpen={true} onCloseDialog={onCloseTasksPrint} tasks={filteredOrders} />
      )}
      <DeleteDialog
        open={Boolean(itemToDelete)}
        onClose={() => setItemToDelete(null)}
        onClick={deleteTask}
        title="Delete task?"
        message="Are you sure you want to delete this task? This cannot be undone!"
      />
    </>
  );
}

export default Task;
