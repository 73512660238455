// eslint-disable-next-line import/no-extraneous-dependencies
import { Document, Page, Text, View } from '@react-pdf/renderer';
import format from 'date-fns/format';

import { BlockWithLabelAndMdf } from 'api/mdfBlocks/types';
import { EditorValue, Instance } from 'types';
import { OrderWithMdf } from 'types/forms/forms';
import { Mdf, MemberType } from 'types/graphqlTypes';

import CommonDocFooter from '../components/CommonDocFooter';

import { getDocumentComponent } from './utils';

import { styles } from './styles';

interface CommonProps {
  instance: Instance;
  clipDuration?: string;
  scriptDuration?: string;
  totalDuration?: string;
}

interface Props extends CommonProps {
  instance: Instance;
  content: EditorValue;
  blocks: BlockWithLabelAndMdf[];
  orders: OrderWithMdf[];
  mdfsSeparated: {
    defaults: Mdf[];
    custom: Mdf[];
    subTypes: Mdf[];
    instances: Mdf[];
  };
  relationMembers: MemberType[];
  contacts: MemberType[];
}

export const InstanceDocHeader = ({
  instance,
  clipDuration = '00:00',
  scriptDuration = '00:00',
  totalDuration = '00:00',
}: CommonProps) => {
  const { mProperties, mPublishingAt, mTitle } = instance;

  return (
    <View style={styles.header} fixed>
      <View style={styles.headerContent}>
        <View style={[styles.column, { width: '60%' }]}>
          <Text style={styles.name}>{mProperties.platform}</Text>
          <Text style={styles.title}>{mTitle}</Text>
          <Text style={styles.scheduleInfo}>
            {mPublishingAt ? (
              <>Scheduled: {format(mPublishingAt, 'MMM D YYYY, HH:mm:ss (Z)')}</>
            ) : (
              <>Not Scheduled</>
            )}
          </Text>
        </View>
        <View style={[styles.row, { justifyContent: 'space-between', flex: 1 }]}>
          <View style={[styles.column, { gap: '0.5rem' }]}>
            <Text style={styles.timingInfoTitle}>Duration</Text>
            <Text style={styles.timingInfoValue}>{clipDuration || '00:00'}</Text>
          </View>
          <View style={[styles.column, { gap: '0.5rem' }]}>
            <Text style={styles.timingInfoTitle}>Script</Text>
            <Text style={styles.timingInfoValue}>{scriptDuration || '00:00'}</Text>
          </View>
          <View style={[styles.column, { gap: '0.5rem' }]}>
            <Text style={styles.timingInfoTitle}>Total</Text>
            <Text style={styles.timingInfoValue}>{totalDuration || '00:00'}</Text>
          </View>
        </View>
      </View>
      <View style={[styles.horizontalLine, { paddingTop: '8' }]} />
    </View>
  );
};

export const InstanceContentPages = ({
  instance,
  content,
  clipDuration,
  scriptDuration,
  totalDuration,
  blocks,
  orders,
  mdfsSeparated,
  relationMembers,
  contacts,
}: Props) => {
  const { document = [] } = content ?? {};

  return (
    <Page style={styles.body}>
      <InstanceDocHeader
        instance={instance}
        clipDuration={clipDuration}
        scriptDuration={scriptDuration}
        totalDuration={totalDuration}
      />
      {document && document.length > 0 && (
        <View style={[styles.column, styles.content]}>
          {document.map((doc) => {
            return getDocumentComponent({
              doc,
              blocks,
              orders,
              mdfsSeparated,
              relationMembers,
              contacts,
            });
          })}
        </View>
      )}
      <CommonDocFooter />
    </Page>
  );
};

export const InstanceMetadataPage = ({
  instance,
  clipDuration,
  scriptDuration,
  totalDuration,
}: CommonProps) => {
  const { mMetaData } = instance;

  return (
    <Page style={styles.body}>
      <InstanceDocHeader
        instance={instance}
        clipDuration={clipDuration}
        scriptDuration={scriptDuration}
        totalDuration={totalDuration}
      />
      <View style={[styles.column, { width: '100%' }]}>
        <Text style={{ fontSize: 20, lineHeight: '200%', marginBottom: 8 }}>Metadata</Text>
        <View style={styles.tableContainer}>
          {mMetaData?.map((metadata) => {
            const displayName = () => {
              if (metadata.key.includes('total')) return 'Duration';
              if (metadata.key.includes('word')) return 'Words';
              if (metadata.key.includes('isFloat')) return 'Floated';
              return metadata.key.split('-').slice(1).join(' ');
            };
            if (
              metadata.key.includes('total') ||
              metadata.key.includes('word') ||
              metadata.key.includes('isFloat')
            )
              return (
                <View key={metadata.key} style={styles.tableRow}>
                  <Text style={styles.tableCell}>{displayName()}</Text>
                  <Text style={styles.tableCell}>{metadata.value}</Text>
                </View>
              );
          })}
        </View>
      </View>
      <CommonDocFooter />
    </Page>
  );
};

const InstancePrintDoc = ({
  instance,
  content,
  clipDuration,
  scriptDuration,
  totalDuration,
  blocks,
  orders,
  mdfsSeparated,
  relationMembers,
  contacts,
}: Props) => (
  <Document>
    <InstanceContentPages
      instance={instance}
      content={content}
      clipDuration={clipDuration}
      scriptDuration={scriptDuration}
      totalDuration={totalDuration}
      blocks={blocks}
      orders={orders}
      mdfsSeparated={mdfsSeparated}
      relationMembers={relationMembers}
      contacts={contacts}
    />
    <InstanceMetadataPage
      instance={instance}
      clipDuration={clipDuration}
      scriptDuration={scriptDuration}
      totalDuration={totalDuration}
    />
  </Document>
);

export default InstancePrintDoc;
