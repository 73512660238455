import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import isNull from 'lodash/isNull';

import { initialValues } from 'components/editor/constants';
import { ActionTypesEnum } from 'components/editor/constants/types/actionTypes';
import variants from 'components/editor/constants/types/editorVariants';
import UserContext from 'contexts/UserContext';
import { Asset, Story } from 'types';
import { EditorValue } from 'types/editor';
import { getAssetData, getFileAssetData } from 'utils/assetData';
import { uploadToS3 } from 'utils/s3Utils';

import useCreateAsset, { AssetInput } from './useCreateAsset';
import useDebouncedCallback from './useDebouncedCallback';
import useGetUser from './useGetUser';
import useLockMember from './useLockMember';
import useTextStorage from './useTextStorage';
import useUnlockMember from './useUnlockMember';

type UpdateInput =
  | { type: ActionTypesEnum.CHANGE; payload: EditorValue }
  | {
      type: ActionTypesEnum.COMMIT_UPDATE;
      payload: EditorValue;
      commitFor: 'asset' | 'userInitiated';
    }
  | { type: ActionTypesEnum.CREATE_ASSET; payload: { asset: Asset } }
  | { type: ActionTypesEnum.ASSET_INSERT; payload: { file: File } };

const useStoryContent = (
  story: Story | undefined,
  canUpdate: boolean,
  currentEditingRef?: React.MutableRefObject<string | null>,
) => {
  const { mId: storyId, mContentKey, locked } = story ?? {};
  const { mId: currentUserId } = useContext(UserContext);
  const { getUserTitle } = useGetUser();
  const [lockStory] = useLockMember();
  const [unlockStory] = useUnlockMember();

  const [createStoryAsset] = useCreateAsset();

  const [content, setContent] = useState<EditorValue | null>(
    initialValues(variants.GENERAL, undefined, undefined, false),
  );
  const [shouldResetSelection, setShouldResetSelection] = useState<boolean>(false);
  const [writeLock, setWriteLock] = useState<boolean>(false);
  const [readLock, setReadLock] = useState<boolean>(false);
  const [lockedByUser, setLockedByUser] = useState<string>('Someone');
  const [isSavingContent, setIsSavingContent] = useState<boolean>(false);
  const [locking, setLocking] = useState<boolean>(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const editorValueRef = useRef<EditorValue | null>(null);
  const writeLockRef = useRef<boolean>(writeLock);
  const storyRef = useRef<Story | null>(story ?? null);
  const initialContentRef = useRef<EditorValue | null>(null);

  const {
    data: s3Data,
    loading,
    refetch,
  } = useTextStorage(mContentKey ?? '', writeLock || !mContentKey);

  const createAsset = useCallback(
    async (assetData: AssetInput) => {
      const asset = getAssetData(storyId!, assetData);
      const result = await createStoryAsset(storyId!, asset, true);

      return result;
    },
    [createStoryAsset, storyId],
  );

  const onAssetInsert = useCallback(
    async (file: File) => {
      if (!story?.mId) return;
      const assetData = getFileAssetData(story?.mId, file);
      const sourceData = {
        mId: assetData.mId,
        mRefId: assetData.mRefId,
        src: '',
      };

      try {
        const result = await createStoryAsset(story?.mId, assetData, true);
        const { createAssets: assets } = result.data as { createAssets: Asset[] };
        if (assets?.[0]) {
          sourceData.src = assets[0].mContentKey;
        }
      } catch (e) {
        // logger.log(e)
      }

      return sourceData;
    },
    [createStoryAsset, story?.mId],
  );

  const onResetEditorValue = useCallback((newValue: EditorValue | null) => {
    if (newValue) {
      setContent({ ...newValue });
      editorValueRef.current = newValue;
      setShouldResetSelection(true);
    } else if (isNull(newValue)) {
      setContent(null);
      editorValueRef.current = null;
      setShouldResetSelection(true);
    }
  }, []);

  const updateLock = useCallback(
    (lockedId: string | null | undefined) => {
      if (lockedId) {
        if (lockedId === currentUserId && currentEditingRef?.current === storyId) {
          setWriteLock(true);
          setReadLock(false);
        } else {
          setWriteLock(false);
          setReadLock(true);
        }
        const newLockedByUser = getUserTitle(lockedId);
        setLockedByUser(newLockedByUser!);
      } else {
        window.requestAnimationFrame(() => {
          if (currentEditingRef?.current) {
            currentEditingRef.current = null;
          }
          setWriteLock(false);
          setReadLock(false);
          setLockedByUser('');
        });
      }
      setLocking(false);
      writeLockRef.current = lockedId === currentUserId;
    },
    [currentEditingRef, currentUserId, getUserTitle, storyId],
  );

  const save = useCallback(
    async (newContent: EditorValue) => {
      const file = new window.File([JSON.stringify(newContent ?? {})], 'content.data', {
        type: 'text/plain',
      });

      await uploadToS3(mContentKey, file);
    },
    [mContentKey],
  );

  const onUnlockStory = useCallback(
    async (cancelled?: boolean) => {
      if (!storyRef.current?.mId) return;

      if (cancelled) {
        await unlockStory(storyRef.current?.mId, editorValueRef.current, true);
      } else {
        await unlockStory(storyRef.current?.mId, editorValueRef.current);
      }
      if (currentEditingRef?.current !== undefined) {
        currentEditingRef.current = null;
      }
      updateLock(null);
    },
    [currentEditingRef, unlockStory, updateLock],
  );

  const onForceUnlock = useCallback(async () => {
    if (!story?.mId) return;
    await unlockStory(story?.mId);
  }, [story?.mId, unlockStory]);

  const tryLockStory = useCallback(async () => {
    if (!writeLock && !locked && storyId) {
      const result = await lockStory(storyId, currentUserId);
      if (currentEditingRef?.current !== undefined) {
        currentEditingRef.current = storyId;
      }
      const lockedId = result?.data?.lockMember?.locked;
      updateLock(lockedId);
      return lockedId;
    } else {
      updateLock(storyRef?.current?.locked);
      return storyRef?.current?.locked;
    }
  }, [writeLock, locked, storyId, lockStory, currentUserId, currentEditingRef, updateLock]);

  const onFocusEditor = useCallback(async () => {
    if (canUpdate && storyId && !writeLock && !readLock && !loading) {
      try {
        setLocking(true);
        refetch();

        return await tryLockStory();
      } catch (error) {
        updateLock(null);
        setLocking(false);
        return null;
      }
    }
  }, [canUpdate, storyId, writeLock, readLock, loading, refetch, tryLockStory, updateLock]);

  const onSaveContent = useCallback(
    async (shouldReleaseLock = true) => {
      if (!loading && writeLockRef.current) {
        setIsSavingContent(true);

        if (editorValueRef.current) await save(editorValueRef.current);

        if (shouldReleaseLock) {
          if (storyId === storyRef.current?.mId) {
            onResetEditorValue(editorValueRef.current);
            initialContentRef.current = editorValueRef.current;
          }
          await onUnlockStory();
        }
        setIsSavingContent(false);
      }
    },
    [loading, onUnlockStory, onResetEditorValue, save, storyId],
  );

  const onDebouncedSave = useCallback(() => onSaveContent(false), [onSaveContent]);

  const [debouncedSave, cancelDebounce] = useDebouncedCallback(onDebouncedSave, 15000);

  const onSave = useCallback(
    async (releaseLock = true) => {
      cancelDebounce();
      await onSaveContent(releaseLock);
    },
    [cancelDebounce, onSaveContent],
  );

  const onCancel = useCallback(async () => {
    setIsCancelled(true);
    cancelDebounce();
    const initialValue = isNull(initialContentRef.current)
      ? initialValues(variants.GENERAL, undefined, undefined, false)
      : initialContentRef.current;

    onResetEditorValue(initialValue);
    await Promise.all([save(initialValue), onUnlockStory(true)]);

    setIsCancelled(false);
  }, [cancelDebounce, onResetEditorValue, save, onUnlockStory]);

  const onChange = useCallback(
    (newContent: EditorValue) => {
      if (writeLockRef.current) {
        editorValueRef.current = newContent;
        void debouncedSave();
      }
    },
    [debouncedSave],
  );

  const onEditorUpdate = (input: UpdateInput) => {
    const { type, payload } = input;
    if (type === ActionTypesEnum.CHANGE) {
      onChange(payload);
    }
    if (type === ActionTypesEnum.COMMIT_UPDATE) {
      onSave(false).then(
        () => {},
        () => {},
      );
    }
    if (type === ActionTypesEnum.CREATE_ASSET) {
      const { asset } = payload;
      return createAsset(asset);
    }

    if (type === ActionTypesEnum.ASSET_INSERT) {
      const { file } = payload;
      return onAssetInsert(file);
    }

    return null;
  };

  const checkVersionRestorability = async () => {
    if (locked === currentUserId) return true;
    const lockedId = await tryLockStory();
    return lockedId === currentUserId;
  };

  const onRestoreVersion = useCallback(
    async (newContent: EditorValue) => {
      onResetEditorValue(newContent);
      await onSave(true);
    },
    [onSave, onResetEditorValue],
  );

  const unloadFunc = useCallback(async (e?: BeforeUnloadEvent) => {
    e?.preventDefault();
    delete e?.returnValue;

    if (
      writeLockRef.current &&
      storyRef.current?.mId === storyId &&
      storyRef.current?.locked === currentUserId &&
      currentEditingRef?.current === storyId &&
      editorValueRef.current
    ) {
      setIsSavingContent(true);
      cancelDebounce();
      await Promise.all([save(editorValueRef.current), onUnlockStory()]);
      setIsSavingContent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /** refetch content when story is unlocked */
    if (storyRef.current?.locked && !story?.locked) refetch();

    storyRef.current = story ?? null;
  }, [refetch, story]);

  useEffect(() => {
    writeLockRef.current = writeLock;
  }, [writeLock]);

  useEffect(() => {
    if (s3Data) {
      onResetEditorValue(s3Data);
      initialContentRef.current = s3Data;
    } else {
      const defaultValue = initialValues(variants.GENERAL, undefined, undefined, false);
      onResetEditorValue(defaultValue);
      initialContentRef.current = defaultValue;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s3Data]);

  useEffect(() => {
    updateLock(locked);
  }, [storyId, locked, updateLock]);

  return {
    loading: loading || locking,
    content,
    shouldResetSelection,
    isSavingContent,
    lockedByUser,
    isCurrentUser: locked === currentUserId,
    readLock,
    writeLock,
    isCancelled,
    onFocusEditor,
    onChange,
    onSave,
    onCancel,
    checkVersionRestorability,
    onRestoreVersion,
    onForceUnlock,
    unloadFunc,
    onEditorUpdate,
  } as const;
};

export default useStoryContent;
