import { lazy, Suspense, useCallback } from 'react';

import LoadingIndicator from 'components/loadingIndicator';
import { PlanningGridContainer } from 'features/grids/planning/PlanningGridContainer';
import OrderGridFull from 'features/orderForm/components/OrderGridFull';
import memberTypes from 'operations/memberTypes';
import { StoryMetadata } from 'screens/story/components/metadata/StoryMetadata';
import { storyTabs, StoryTabValue } from 'store';
import { GetOrderEnum, MemberTypeEnum } from 'types/graphqlTypes';

import { useStoryMolecule } from './store/story';
import { useStoryPaneMolecule } from './store/storyPane';
import { pitchTypesSet } from './utils/typeSets';
import Tabs from './Tabs';
import usePanes from './useStoryPanes';

import { ContentContainer, TabsContent, TabsRoot } from './styled';

const Content = lazy(() => import('./Content'));
const Notes = lazy(() => import('features/notes/Notes'));
const Instances = lazy(() => import('./Instances'));
const Assets = lazy(() => import('features/assets/Assets'));
const MapView = lazy(() => import('components/map'));
const Resources = lazy(() => import('features/resources'));
const Booking = lazy(() => import('features/schedule'));

interface Props {
  pane: {
    tab: string;
    tId?: string;
  };
}

function StoryTabs({ pane }: Readonly<Props>) {
  const { updateStoryPane } = usePanes();
  const { useStory, useCanUpdateStoryValue } = useStoryMolecule();
  const { usePaneIndexValue, useSelectedStoryInstances } = useStoryPaneMolecule();

  const [story] = useStory();
  const canUpdate = useCanUpdateStoryValue();
  const paneIndex = usePaneIndexValue();
  const [selectedStoryInstances, setSelectedStoryInstances] = useSelectedStoryInstances();

  const onTabChange = useCallback(
    (value: string) => {
      const [currentInstanceId] = selectedStoryInstances;
      if (value === storyTabs.instances && currentInstanceId) {
        updateStoryPane(paneIndex, value, currentInstanceId);
        setSelectedStoryInstances([currentInstanceId]);
        return;
      }
      updateStoryPane(paneIndex, value as StoryTabValue);
    },
    [paneIndex, selectedStoryInstances, setSelectedStoryInstances, updateStoryPane],
  );

  if (story)
    return (
      <TabsRoot
        onValueChange={onTabChange}
        defaultValue={storyTabs.content}
        value={pane?.tab !== '' ? pane.tab : storyTabs.content}
      >
        <Tabs />
        <Suspense fallback={<LoadingIndicator />}>
          <ContentContainer>
            <TabsContent value={storyTabs.content}>
              <Content />
            </TabsContent>
            <TabsContent value={storyTabs.notes}>
              <Notes />
            </TabsContent>
            <TabsContent value={storyTabs.instances}>
              <Instances />
            </TabsContent>
            <TabsContent value={storyTabs.assets}>
              <Assets
                memberType={story?.mType || MemberTypeEnum.Story}
                mId={story?.mId}
                canUpdate={canUpdate}
              />
            </TabsContent>
            <TabsContent value={storyTabs.bookings}>
              <Booking
                storyId={story?.mId}
                storyTitle={story?.mTitle}
                storyThumbnail={story?.mThumbnailUrl}
                isArchivedStory={story?.mState === 'archived'}
                isStoryBox={false}
              />
            </TabsContent>
            <TabsContent value={storyTabs.locations}>
              <MapView
                memberId={story.mId}
                memberType={pitchTypesSet.has(story?.mType) ? memberTypes.PITCH : memberTypes.STORY}
              />
            </TabsContent>
            <TabsContent value={storyTabs.metadata}>
              <StoryMetadata story={story} useCollapse={false} />
            </TabsContent>
            <TabsContent value={storyTabs.resources}>
              <Resources
                storyId={story?.mId}
                mAssignedMembers={story?.mAssignedMembers}
                dialogHeight="87vh"
                enableUpdate={canUpdate}
              />
            </TabsContent>
            <TabsContent value={storyTabs.blocks}>
              <PlanningGridContainer resourceId={story?.mId} resource={story} />
            </TabsContent>
            <TabsContent value={storyTabs.tasks}>
              <OrderGridFull resourceId={story?.mId} resourceType={GetOrderEnum.Resource} />
            </TabsContent>
          </ContentContainer>
        </Suspense>
      </TabsRoot>
    );
}

export default StoryTabs;
