import { useContext } from 'react';

import { BlockWithLabelAndMdf } from 'api/mdfBlocks/types';
import { useSearch } from 'api/search/useSearch';
import UserContext from 'contexts/UserContext';
import { useGetMembersFromFieldValue } from 'features/gridDeck/api/useBatchGetItems';
import { OrderWithMdf } from 'types/forms/forms';
import { SearchItemTypeEnum } from 'types/graphqlTypes';
import getContactFieldsFromBlocks from 'utils/mdf/getContactFieldsFromBlocks';
import getContactFieldsFromTasks from 'utils/mdf/getContactFieldsFromTasks';
import getRelationFieldsFromBlocks from 'utils/mdf/getRelationFieldsFromBlocks';
import getRelationFieldsFromTasks from 'utils/mdf/getRelationFieldsFromTasks';

const useGetContactsAndRelationMembers = (
  blocksWithMdf: BlockWithLabelAndMdf[] = [],
  tasksWithMdf: OrderWithMdf[] = [],
  subTasksWithMdf: OrderWithMdf[] = [],
) => {
  const { groups } = useContext(UserContext);

  const blockRelationFields = getRelationFieldsFromBlocks(blocksWithMdf, groups);
  const blockContactIds = getContactFieldsFromBlocks(blocksWithMdf, groups);
  const taskRelationFields = getRelationFieldsFromTasks(tasksWithMdf, groups);
  const taskContactIds = getContactFieldsFromTasks(tasksWithMdf, groups);
  const subTaskRelationFields = getRelationFieldsFromTasks(subTasksWithMdf, groups);
  const subTaskContactIds = getContactFieldsFromTasks(subTasksWithMdf, groups);

  const contactIds = Array.from(
    new Set([...blockContactIds, ...taskContactIds, ...subTaskContactIds]),
  );

  const combinedRelationFieldsMap = new Map();

  [blockRelationFields, taskRelationFields, subTaskRelationFields].forEach((array) =>
    array.forEach((rField) => combinedRelationFieldsMap.set(rField.id, rField)),
  );

  const relationFields = Array.from(combinedRelationFieldsMap.values());

  const {
    members: relationMembers,
    loading: relationsLoading,
    error: relationsError,
  } = useGetMembersFromFieldValue(relationFields);

  const {
    items: contacts,
    loading: contactsLoading,
    error: contactsError,
  } = useSearch({
    skip: false,
    searchString: '',
    mIds: contactIds,
    toolbarState: {
      sortBy: 'createdAt',
      order: 'desc',
      rangeBy: null,
      defaultMdfId: null,
      mdfId: '',
      isFiltering: false,
      statusFilter: [],
      mTypes: [SearchItemTypeEnum.contact],
      assignedIds: [],
      createdByIds: [],
    },
  });

  return {
    relationMembers,
    relationsLoading,
    relationsError,
    contacts,
    contactsLoading,
    contactsError,
  };
};

export default useGetContactsAndRelationMembers;
