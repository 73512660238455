import { memo } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as PhotoIcon } from 'assets/icons/systemicons/editor/photo_small.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/systemicons/editor/video_small.svg';
import { elementTypes } from 'components/editor/constants';

import BlockButton from '../blockButton';
import TextButton from '../textButton';

import { ToolbarContainer, Empty, InnerWrapper, OuterWrapper } from '../../styled';

const { IMAGE, VIDEO, PHOTO_GALLERY } = elementTypes;
const placeHolders = [...Array(20).keys()];
const cmsVariants = {
  IFRAME: 'iframe',
  BLOCKS: 'blocks',
};

function Wrapper({ children }) {
  return (
    <OuterWrapper>
      <InnerWrapper>{children}</InnerWrapper>
    </OuterWrapper>
  );
}

function WithContainer({ children, skipContainer }) {
  return skipContainer ? (
    children
  ) : (
    <ToolbarContainer>
      {children}
      {placeHolders.map((key) => (
        <Empty key={key} />
      ))}
    </ToolbarContainer>
  );
}

const CmsToolbar = ({ platformStructure, skipContainer }) => {
  const { variant, sections, allowVideoInPhotogallery } = platformStructure;
  return (
    <WithContainer skipContainer={skipContainer}>
      {variant !== cmsVariants.IFRAME ? (
        sections.map((section) => (
          <Wrapper key={section.name}>
            {section.blocks.map((block) => (
              <BlockButton
                key={block}
                type={block}
                tooltipType={
                  block === PHOTO_GALLERY && allowVideoInPhotogallery ? 'GALLERY' : block
                }
              />
            ))}
          </Wrapper>
        ))
      ) : (
        <Wrapper>
          <TextButton icon={<VideoIcon />} label="Add Video" type={VIDEO} />
          <TextButton icon={<PhotoIcon />} label="Add Photo" type={IMAGE} />
        </Wrapper>
      )}
    </WithContainer>
  );
};

CmsToolbar.propTypes = {
  /** platform structure for the block */
  platformStructure: PropTypes.shape({
    variant: PropTypes.string,
    section: PropTypes.arrayOf(PropTypes.object),
  }),
};

CmsToolbar.defaultProps = {
  platformStructure: {
    variant: 'iframe',
    sections: [],
  },
};

export default memo(CmsToolbar);
