/* eslint-disable import/no-extraneous-dependencies */
import { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { isEqual, keyBy } from 'lodash';

import { useCreateIntegration } from 'api/config/useCreateIntegration';
import { useDeleteIntegration } from 'api/config/useDeleteIntegration';
import { useGetIntegrationsForAdmin } from 'api/config/useGetIntegrations';
import { IconButton } from 'components/buttons';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import { useEditStringDialog } from 'components/editStringDialog/EditStringDialog';
import Scrollbar from 'components/scrollbar';
import SplitBar from 'components/split/SplitBar';
import useToast from 'components/toast/useToast';
import { HStack } from 'layouts/box/Box';
import { IntegrationEnum, IntegrationType } from 'types/graphqlTypes';

import { useChangedIntegrationWebhooks } from '../../atomsTs';
import { HeaderCell } from '../settingsTabs/systemSettings/details/studios/styled';

import { EditWebhookRow } from './EditWebhookRow';
import getPayload from './payload';

import { Formatted, Label, PayloadWrapper, Viewer } from './styled';
export const programmaticIdRegex = /^[a-zA-Z0-9-_]*$/;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const columns = [
  { key: 'mTitle', name: 'Title' },
  { key: 'mDescription', name: 'Description' },
  { key: 'mProvider', name: 'Provider' },
  { key: 'mTypes', name: 'Types' },
  { key: 'endpoint', name: 'Endpoint' },
  { key: 'auth', name: 'Auth' },
  { key: 'mActive', name: 'Active' },
  { key: 'mUpdatedAt', name: 'Updated' },
  { key: 'action', name: '' },
];

export function EditWebhooks() {
  const [selectedWebhook, setSelectedWebhook] = useState<IntegrationType | null>(null);
  const { integrations: webhooks } = useGetIntegrationsForAdmin(IntegrationEnum.Webhook);

  const { createIntegration } = useCreateIntegration();
  const { deleteIntegration } = useDeleteIntegration();
  const { errorToast } = useToast();
  const [changedWebhook, setChangedWebhook] = useChangedIntegrationWebhooks();

  const [webhookToDelete, setWebhookToDelete] = useState<IntegrationType | null>(null);
  const [, showEditStringDialog] = useEditStringDialog();

  const webhooksById = useMemo(() => {
    return keyBy(webhooks, (act) => act.mRefId);
  }, [webhooks]);

  const onDelete = useCallback(
    (id: string) => {
      const webhook = webhooks.find((a) => a.mRefId === id);
      if (webhook) {
        setWebhookToDelete(webhook);
      }
    },
    [setWebhookToDelete, webhooks],
  );

  const onResetWebhook = useCallback(
    (id: string) => {
      if (changedWebhook[id]) {
        const copy = { ...changedWebhook };
        delete copy[id];
        setChangedWebhook(copy);
      }
    },
    [setChangedWebhook, changedWebhook],
  );

  const doDeleteWebhook = useCallback(
    (webhookIdToDelete: string | undefined, webhookType: string | undefined) => {
      if (webhookIdToDelete) {
        const Integration =
          webhookType == 'endpoint' ? IntegrationEnum.EndPoint : IntegrationEnum.Webhook;
        deleteIntegration(webhookIdToDelete, Integration)
          .then(() => {
            if (changedWebhook[webhookIdToDelete]) {
              const copy = { ...changedWebhook };
              delete changedWebhook[webhookIdToDelete];
              setChangedWebhook(copy);
            }
          })
          .catch(errorToast);
      }
      setWebhookToDelete(null);
    },
    [deleteIntegration, changedWebhook, setChangedWebhook, setWebhookToDelete],
  );

  const payloadWebhooks = useMemo(() => {
    const types = selectedWebhook?.mTypes?.length
      ? selectedWebhook.mTypes
      : selectedWebhook?.mAllowedEntities?.mType ?? [];

    if (types.length === 0) {
      return '{}';
    }
    return types.reduce((acc, cur) => {
      const payload = getPayload(cur) ?? {};
      acc =
        acc.length === 0
          ? JSON.stringify(payload, null, 1)
          : `${acc}, ${JSON.stringify(payload, null, 1)}`;
      return acc;
    }, '');
  }, [selectedWebhook]);

  const doCreateWebhook = useCallback(() => {
    showEditStringDialog({
      headerText: 'Create new webhook',
      required: true,
      inputLabel: 'Identifier',
      validator: (val: string) => {
        if (val.length < 3) {
          return 'Minimum length is 3 characters';
        }
        if (!programmaticIdRegex.test(val)) {
          return 'Only alphanumeric characters allowed';
        }
        if (webhooksById[val]) {
          return 'Webhook already exists';
        }
        return true;
      },
      onConfirm: (val) => {
        createIntegration(val, IntegrationEnum.Webhook).catch(errorToast);
      },
    });
  }, [showEditStringDialog, createIntegration, webhooksById]);

  const onWebhookChange = useCallback(
    (webhookUpdates: Partial<IntegrationType>, originalWebhook: IntegrationType) => {
      const updatedWebhook: IntegrationType = {
        ...originalWebhook,
        ...webhookUpdates,
      };

      if (!changedWebhook[originalWebhook.mRefId] && !isEqual(updatedWebhook, originalWebhook)) {
        setChangedWebhook((prevState) => {
          return {
            ...prevState,
            [originalWebhook.mRefId]: updatedWebhook,
          };
        });
      } else if (changedWebhook[originalWebhook.mRefId]) {
        const updatedChangedWebhook: IntegrationType = {
          ...changedWebhook[originalWebhook.mRefId],
          ...webhookUpdates,
        };
        if (isEqual(updatedChangedWebhook, originalWebhook)) {
          const copy = { ...changedWebhook };
          delete copy[originalWebhook.mRefId];
          setChangedWebhook(copy);
        } else {
          setChangedWebhook((prevState) => {
            return {
              ...prevState,
              [originalWebhook.mRefId]: updatedChangedWebhook,
            };
          });
        }
      }
    },
    [changedWebhook, setChangedWebhook],
  );

  const memoizedTable = useMemo(() => {
    return (
      <Scrollbar
        valueChanged={undefined}
        closeToBottom={undefined}
        top={undefined}
        bottom={undefined}
        dark={undefined}
        showHorizontal={undefined}
      >
        <Table aria-label="actions-table">
          <TableHead key="actions-table-head">
            <TableRow key="actions-header">
              <HeaderCell key="externalId">
                <HStack justifyContent="start">
                  <IconButton
                    title="Create new webhook"
                    size={24}
                    iconSize={20}
                    usage="text"
                    onClick={doCreateWebhook}
                  >
                    <Add />
                  </IconButton>
                  Id
                </HStack>
              </HeaderCell>
              {columns.map((col) => (
                <HeaderCell key={col.key}>{col.name}</HeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody key="actions-table-body">
            {webhooks.map((hook) => (
              <EditWebhookRow
                hasChanges={changedWebhook[hook.mRefId] !== undefined}
                key={hook.mRefId}
                webhook={changedWebhook[hook.mRefId] ?? hook}
                isSelected={hook.mRefId === selectedWebhook?.mRefId}
                onSelect={(webhook) => setSelectedWebhook(webhook)}
                onDeleteWebhook={onDelete}
                onUpdateWebhook={(val) => onWebhookChange(val, hook)}
                onResetWebhook={onResetWebhook}
              />
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    );
  }, [
    columns,
    webhooks,
    changedWebhook,
    selectedWebhook,
    doCreateWebhook,
    setSelectedWebhook,
    onDelete,
    onWebhookChange,
    onResetWebhook,
  ]);

  return (
    <Scrollbar
      valueChanged={undefined}
      closeToBottom={undefined}
      top={undefined}
      bottom={undefined}
      dark={undefined}
      showHorizontal={undefined}
    >
      <Wrapper>
        {selectedWebhook ? (
          <SplitBar
            split={undefined}
            style={{
              height: '100%',
            }}
            primary="second"
            pane1Style={{
              minWidth: '500px',
            }}
            pane2Style={{
              minWidth: '250px',
              maxWidth: '500px',
            }}
          >
            {memoizedTable}
            <PayloadWrapper>
              <Label>Example Payload</Label>
              <Viewer>
                <Formatted>{payloadWebhooks}</Formatted>
              </Viewer>
            </PayloadWrapper>
          </SplitBar>
        ) : (
          <>{memoizedTable}</>
        )}
        <DeleteDialog
          open={webhookToDelete !== null}
          onClose={() => setWebhookToDelete(null)}
          onClick={() => doDeleteWebhook(webhookToDelete?.mRefId, webhookToDelete?.mId)}
          title="Delete webhook?"
          message={`Are you sure you want to delete ${webhookToDelete?.mTitle}?`}
        />
      </Wrapper>
    </Scrollbar>
  );
}
