import { useCallback, useMemo } from 'react';

import { createBlockInput } from 'api/mdfBlocks/types';
import { useCreateBlock } from 'api/mdfBlocks/useCreateMdfBlock';
import { ReactComponent as PlusIcon } from 'assets/icons/systemicons/plus_ultraSmall_circle.svg';
import { Button } from 'components/buttons';
import DropdownMenu from 'components/dropdownMenu/DropdownMenu';
import { Resource } from 'hooks/useResourceDetails';
import { useEditorCommands } from 'store';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';

export type CreateDropdownProps = {
  resource: Resource;
};

/** Create planning item dropdown menu */
export function CreateDropdown({ resource }: Readonly<CreateDropdownProps>) {
  const [editorCommands] = useEditorCommands();
  const { createBlock } = useCreateBlock();

  const filteredCommands = useMemo(
    () => editorCommands.filter((cmd) => cmd.mResourceType !== MemberTypeEnum.OrderForm),
    [editorCommands],
  );

  const onSelectMenu = useCallback(
    async (cmd: EditorCommandConfigType) => {
      await createBlock(createBlockInput(resource, cmd));
    },
    [resource],
  );

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger>
        <Button
          title="Create planning item"
          aria-label="Toggle columns"
          variant="contained"
          usage="cta"
          height={32}
          startIcon={<PlusIcon className="skipOverride" />}
        >
          New planning item
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content>
          <DropdownMenu.Header>Create new planning item</DropdownMenu.Header>
          {filteredCommands.map((cmd) => (
            <DropdownMenu.Item key={cmd.mId} onSelect={() => void onSelectMenu(cmd)}>
              {cmd.mTitle}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu>
  );
}
