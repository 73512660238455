import { Document, Page, Text, View } from '@react-pdf/renderer';
import format from 'date-fns/format';

import { BlockWithLabelAndMdf } from 'api/mdfBlocks/types';
import { EditorValue, Story } from 'types';
import { OrderWithMdf } from 'types/forms/forms';
import { Mdf, MemberType } from 'types/graphqlTypes';

import CommonDocFooter from '../components/CommonDocFooter';
import StoryIcon from '../components/StoryIcon';

import { getDocumentComponent } from './utils';

import { styles } from './styles';

const ContentDocHeader = ({ story }: { story: Story }) => {
  return (
    <View style={styles.header} fixed>
      {story?.mPublishingAt && (
        <Text style={styles.timingInfoTitle}>
          {format(story.mPublishingAt, 'ddd. MMMM DD, YYYY')}
        </Text>
      )}
      <View style={[styles.headerContent, { alignItems: 'center' }]}>
        <StoryIcon />
        <Text style={styles.title}>{story.mTitle}</Text>
      </View>
      <View style={[styles.horizontalLine, { paddingTop: '8' }]} />
    </View>
  );
};

const Contents = ({ content, blocks, orders, mdfsSeparated, relationMembers, contacts }: Props) => {
  const { document = [] } = content;

  if (document && document.length > 0) {
    return (
      <View style={[styles.column, styles.content]}>
        {document.map((doc) => {
          return getDocumentComponent({
            doc,
            blocks,
            orders,
            mdfsSeparated,
            relationMembers,
            contacts,
          });
        })}
      </View>
    );
  }

  return null;
};

interface Props {
  content: EditorValue;
  story: Story;
  blocks: BlockWithLabelAndMdf[];
  orders: OrderWithMdf[];
  mdfsSeparated: {
    defaults: Mdf[];
    custom: Mdf[];
    subTypes: Mdf[];
    instances: Mdf[];
  };
  relationMembers: MemberType[];
  contacts: MemberType[];
}

const ContentPrintDoc = ({
  content,
  story,
  blocks,
  orders,
  mdfsSeparated,
  relationMembers,
  contacts,
}: Props) => (
  <Document>
    <Page style={styles.body}>
      <ContentDocHeader story={story} />
      <Contents
        content={content}
        story={story}
        blocks={blocks}
        orders={orders}
        mdfsSeparated={mdfsSeparated}
        relationMembers={relationMembers}
        contacts={contacts}
      />
      <CommonDocFooter />
    </Page>
  </Document>
);

export default ContentPrintDoc;
