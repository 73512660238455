import { useCallback, useMemo, useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { capitalize } from 'lodash';

import { ReactComponent as Edit } from 'assets/icons/systemicons/edit.svg';
import { IconButton } from 'components/buttons';
import Checkbox from 'components/checkbox';
import { StyledFormControl } from 'components/command/toolbar/styled';
import { RowCell } from 'components/editMdfDialog/components/FieldModelRow';
import { Input } from 'components/input/styled';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import { CloseIcon } from 'components/orderFormDialog/styled';
import Popper from 'components/shared/popper';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import LWCheckbox from 'features/orderForm/components/LWCheckbox';
import { Box, HStack, VStack } from 'layouts/box/Box';
import { AuthType, ConfigurableActionMTypes, IntegrationType } from 'types/graphqlTypes';
import { isoToLocaleShort } from 'utils/datetimeHelpers';

import { configurableHookMTypes, TypeToLabel } from './constants';
import { EditAuthDialogue } from './EditAuthDialogue';
import { authOptions, getAuthLabel } from './utils';

import { DeleteButton, ResetButton, StyledRowCell, StyledTableRow, TypesSelector } from './styled';

interface WebhookRowProps {
  webhook: IntegrationType;
  isSelected: boolean;
  hasChanges: boolean;
  onSelect: (action: IntegrationType) => void;
  onDeleteWebhook: (id: string) => void;
  onUpdateWebhook: (updates: Partial<IntegrationType>) => void;
  onResetWebhook: (id: string) => void;
}

export function EditWebhookRow({
  webhook,
  hasChanges,
  onDeleteWebhook,
  onUpdateWebhook,
  onResetWebhook,
  isSelected,
  onSelect,
}: Readonly<WebhookRowProps>) {
  const {
    mRefId,
    mTitle,
    mDescription,
    mProvider,
    mAllowedEntities,
    mInvokeUrl,
    mTypes,
    mUpdatedAt,
    endpoint,
    auth,
    mActive,
    externalId,
  } = webhook;

  const [title, setTitle] = useState(mTitle);
  const [showAuthDialog, setShowAuthDialog] = useState(false);
  const [localEndpoint, setLocalEndpoint] = useState(endpoint ?? mInvokeUrl ?? '');
  const [description, setDescription] = useState(mDescription ?? '');
  const [provider, setProvider] = useState(mProvider ?? '');
  const [anchor, setAnchor] = useState<EventTarget | null>(null);

  const toggleMType = useCallback(
    (type: ConfigurableActionMTypes) => {
      onUpdateWebhook({ mTypes: [type] });
    },
    [mTypes, onUpdateWebhook],
  );

  const selectedLabel = useMemo(() => {
    const selectedMtypes = mTypes ?? mAllowedEntities?.mType ?? [];
    const types = selectedMtypes
      .map((type) => capitalize(TypeToLabel[type as ConfigurableActionMTypes]))
      ?.join(', ');
    if (types) return types;
    return '';
  }, [mTypes, mAllowedEntities]);

  const showAuth = useCallback(() => {
    setShowAuthDialog(true);
  }, [showAuthDialog]);

  const handleBlur = useCallback(() => {
    onUpdateWebhook({
      mTitle: title,
      mDescription: description.length === 0 ? null : description,
      mProvider: provider,
      endpoint: localEndpoint ?? null,
    });
  }, [title, description, provider, localEndpoint, onUpdateWebhook]);

  return (
    <StyledTableRow $selected={isSelected}>
      <StyledRowCell
        style={hasChanges ? { color: 'orange' } : undefined}
        onClick={() => onSelect(webhook)}
      >
        <span>
          {externalId || mRefId}
          {hasChanges ? '*' : ''}
        </span>
      </StyledRowCell>
      <RowCell>
        <Tooltip title={title}>
          <Input
            value={title}
            onChange={(ev) => setTitle(ev.target.value)}
            onBlur={handleBlur}
            placeholder="Type menu label here.."
          />
        </Tooltip>
      </RowCell>
      <RowCell>
        <Tooltip title={description}>
          <Input
            value={description}
            onChange={(ev) => setDescription(ev.target.value)}
            onBlur={handleBlur}
            placeholder="Optional description"
          />
        </Tooltip>
      </RowCell>
      <RowCell>
        <Tooltip title={!provider ? 'This field is required.' : provider}>
          <StyledTextField
            value={provider}
            onChange={(ev) => setProvider(ev.target.value)}
            onBlur={handleBlur}
            placeholder="Provider"
            variant="filled"
            required
            error={!provider}
          />
        </Tooltip>
      </RowCell>
      <RowCell>
        <TypesSelector
          container
          padding="4px"
          height="32px"
          maxWidth="200px"
          borderRadius="4px"
          overflow="hidden"
          justifyContent="flex-start"
          onClick={(ev) => setAnchor(ev.currentTarget)}
        >
          {selectedLabel}
        </TypesSelector>
        <Popper anchorEl={anchor} position="bottom">
          <ClickAwayListener onClickAway={() => setAnchor(null)}>
            <Box padding="5px 15px 5px 5px">
              <VStack alignItems="start">
                {configurableHookMTypes
                  .filter((item) => item.functional)
                  .map((item) => {
                    return (
                      <div key={item.type}>
                        <StyledFormControl
                          control={
                            <Checkbox
                              onClick={() => toggleMType(item.type)}
                              selected={(mTypes ?? mAllowedEntities?.mType ?? []).includes(
                                item.type,
                              )}
                            />
                          }
                          label={capitalize(TypeToLabel[item.type])}
                        />
                      </div>
                    );
                  })}
              </VStack>
              <CloseIcon
                style={{ position: 'absolute', top: '3px', right: '3px' }}
                onClick={() => setAnchor(null)}
              />
            </Box>
          </ClickAwayListener>
        </Popper>
      </RowCell>
      <RowCell>
        <Tooltip title={localEndpoint}>
          <Input
            value={localEndpoint}
            onChange={(ev) => setLocalEndpoint(ev.target.value)}
            onBlur={handleBlur}
            placeholder="REST endpoint"
          />
        </Tooltip>
      </RowCell>
      <RowCell>
        <Box container alignItems="center" justifyContent="start">
          <IconButton
            usage="text"
            size={24}
            iconSize={20}
            onClick={() => showAuth()}
            title="Configure Auth"
          >
            <Edit />
          </IconButton>
          <Text variant="caption"> {getAuthLabel(auth?.type)} </Text>
        </Box>
      </RowCell>
      <RowCell>
        <LWCheckbox selected={mActive} setValue={() => onUpdateWebhook({ mActive: !mActive })} />
      </RowCell>
      <RowCell>{isoToLocaleShort(mUpdatedAt)}</RowCell>
      <RowCell>
        <HStack justifyContent="start">
          <Tooltip title="Discard changes">
            <ResetButton onMouseDown={() => onResetWebhook(webhook.mRefId)} />
          </Tooltip>
          <Tooltip title="Delete field">
            <DeleteButton onMouseDown={() => onDeleteWebhook(webhook.mRefId)} />
          </Tooltip>
        </HStack>
      </RowCell>
      {showAuthDialog && (
        <EditAuthDialogue
          open={showAuthDialog}
          setOpen={setShowAuthDialog}
          alternatives={authOptions}
          savedOptionList={auth?.type ?? AuthType.None}
          doUpdate={onUpdateWebhook}
        />
      )}
    </StyledTableRow>
  );
}
