import { useMemo, useState } from 'react';
import { useTheme } from '@emotion/react';
import { v4 as uuid } from 'uuid';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import { ReactComponent as Add } from 'assets/icons/systemicons/add.svg';
import { ReactComponent as Delete } from 'assets/icons/systemicons/delete_small.svg';
import { IconButton } from 'components/buttons';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import Dialog from 'components/dialogs/DialogBuilder';
import { TaskIcon } from 'components/editor/components/mdf/components/userCommands/styled';
import ColorInput from 'components/input/ColorInput';
import Input from 'components/input/Input';
import Scrollbar from 'components/scrollbar/scrollbar';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { useEditorCommands } from 'store';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';

import { useChangedEditorCommands } from '../../../../../atomsTs';
import useCreateEditorCommand from '../../../../../hooks/useCreateEditorCommand';
import useDeleteEditorCommand from '../../../../../hooks/useDeleteEditorCommand';

import CommandPreview from './CommandPreview';
import ConfigDropdown, { ConfigType } from './ConfigDropdown';
import EditCommandProperties from './EditCommandProperties';

import { CommandItem, FormsWrapper, Header, StyledText, Wrapper } from './styled';

const CONFIG_ID = 'mdfBlock';

function Commands() {
  const theme = useTheme();
  const { keyedOrderForms } = useGetOrderForms();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState<ConfigType | null>(null);
  const [title, setTitle] = useState<string>('');
  const [slashCommand, setSlashCommand] = useState<string>('');
  const [selectedCommand, setSelectedCommand] = useState<EditorCommandConfigType | null>(null);

  const { createCommand } = useCreateEditorCommand();
  const { deleteEditorCommand } = useDeleteEditorCommand();
  const [editorCommands] = useEditorCommands();
  const [changedEditorCommands] = useChangedEditorCommands();
  const { mdfs } = useGetMdfs({ all: true });

  const openCreateDialog = () => setCreateDialogOpen((prev) => !prev);
  const closeCreateDialog = () => setCreateDialogOpen((prev) => !prev);
  const openDeleteDialog = () => setDeleteDialogOpen((prev) => !prev);
  const closeDeleteDialog = () => setDeleteDialogOpen((prev) => !prev);

  const createNewCommand = async () => {
    await createCommand({
      mId: CONFIG_ID,
      mRefId: uuid(),
      mTitle: title,
      mSecId: selectedConfig?.resourceId,
      mTertId: selectedConfig?.id,
      mType: MemberTypeEnum.Config,
      mResourceType: selectedConfig?.type,
      mActive: false,
      slashCommand: `${slashCommand}`,
      singleOnly: false,
    });
    closeCreateDialog();
    setTitle('');
    setSlashCommand('');
    setSelectedConfig(null);
  };

  const onDeleteCommand = async () => {
    await deleteEditorCommand(CONFIG_ID, selectedCommand?.mRefId as string);
    setSelectedCommand(null);
    closeDeleteDialog();
  };

  const syncedSelectedCommand = useMemo(() => {
    const currentCommands = changedEditorCommands.length ? changedEditorCommands : editorCommands;
    const existing = currentCommands.find((c) => c.mRefId === selectedCommand?.mRefId);
    return existing
      ? { ...selectedCommand, ...(existing as EditorCommandConfigType) }
      : selectedCommand;
  }, [changedEditorCommands, selectedCommand, editorCommands]);

  const selectedMdf = useMemo(
    () => mdfs.find((mdf) => mdf.id === selectedCommand?.mSecId),
    [selectedCommand?.mSecId, mdfs],
  );

  return (
    <>
      <Wrapper>
        <FormsWrapper>
          <Header>
            <Text variant="overline" color="highEmphasis">
              Commands
            </Text>
            <IconButton
              size={24}
              iconSize={18}
              usage="text"
              title="Create new command"
              onClick={openCreateDialog}
            >
              <Add />
            </IconButton>
          </Header>
          {editorCommands.map((command) => (
            <CommandItem
              key={command.mRefId}
              onClick={() => setSelectedCommand(command)}
              $selected={command.mRefId === syncedSelectedCommand?.mRefId}
            >
              {command.mResourceType === MemberTypeEnum.OrderForm ? (
                <ColorInput
                  color={keyedOrderForms[command.mTertId]?.mColor}
                  defaultColor={theme.palette.dina.buttonBackgroundHighEmphasis}
                />
              ) : (
                <ColorInput color={command.mColor} />
              )}
              <StyledText
                truncate
                variant="listItemLabel"
                color={
                  command.mRefId === syncedSelectedCommand?.mRefId
                    ? 'whiteHighEmphasis'
                    : 'highEmphasis'
                }
                $isEdited={!!changedEditorCommands.find((c) => c.mRefId === command.mRefId)}
              >
                {command.mTitle}
              </StyledText>
              {command.mResourceType === MemberTypeEnum.OrderForm && (
                <Tooltip title="Configured by order forms">
                  <TaskIcon
                    $selected={command.mRefId === syncedSelectedCommand?.mRefId}
                    height={12}
                    width={12}
                    className="skipOverride"
                  />
                </Tooltip>
              )}
              <IconButton
                size={24}
                usage="text"
                title="Delete command"
                onClick={openDeleteDialog}
                className="deleteButton"
              >
                <Delete className="skipOverride" />
              </IconButton>
            </CommandItem>
          ))}
        </FormsWrapper>
        {syncedSelectedCommand && (
          <Scrollbar>
            <EditCommandProperties commandProperties={syncedSelectedCommand} />
          </Scrollbar>
        )}
        {syncedSelectedCommand && (
          <CommandPreview selectedCommand={syncedSelectedCommand} mdf={selectedMdf} />
        )}
      </Wrapper>
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={closeDeleteDialog}
        onClick={onDeleteCommand}
        title="Delete command"
        message={`Are you sure you want to delete "${syncedSelectedCommand?.mTitle}"?`}
      />
      <Dialog open={createDialogOpen} onClose={closeCreateDialog}>
        <Dialog.Header>Create command</Dialog.Header>
        <Dialog.Body>
          <ConfigDropdown
            value={selectedConfig?.id ?? null}
            onSelectConfig={setSelectedConfig}
            style={{ marginBottom: '5px' }}
            error={!selectedConfig}
            disableClearable
          />
          <Text variant="overline">Title</Text>
          <Input initialValue={title} required updateValue={setTitle} />
          <Text variant="overline">Slash command</Text>
          <Input initialValue={slashCommand} required updateValue={setSlashCommand} />
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.CancelButton />
          <Dialog.ConfirmButton
            disabled={!selectedConfig || !title || !slashCommand}
            label="Create"
            onClick={createNewCommand}
          />
        </Dialog.Footer>
      </Dialog>
    </>
  );
}

export default Commands;
