import { useMemo } from 'react';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { BlockWithLabel } from 'api/mdfBlocks/types';
import Dialog from 'components/dialog';
import LoadingIndicator from 'components/loadingIndicator';
import PdfViewer from 'components/pdfViewer/PdfViewer';
import { useGetOrdersForEntities } from 'screens/space/api/useGetOrdersAndForms';
import { GetOrderEnum, TaskStatusEnum } from 'types/graphqlTypes';

import ErrorView from './components/ErrorView';
import PlanningPrintDoc from './docs/PlanningPrintDoc';
import useGetBlocksWithMdf from './hooks/useGetBlocksWithMdf';
import useGetContactsAndRelationMembers from './hooks/useGetContactsAndRelationMembers';
import useGetOrdersWithMdf from './hooks/useGetOrdersWithMdf';

import { LoadingWrapper, PrintViewWrapper } from './styled';

interface Props {
  isDialogOpen: boolean;
  onCloseDialog: () => void;
  blocks: BlockWithLabel[];
}

const View = ({ blocks }: Pick<Props, 'blocks'>) => {
  const { mdfs, mdfsSeparated, loading, error } = useGetMdfs({ all: true });

  const mIds = useMemo(() => blocks.map((block) => block.mRefId), [blocks]);

  const {
    orders,
    loading: ordersLoading,
    error: ordersError,
  } = useGetOrdersForEntities(mIds, GetOrderEnum.Resource, TaskStatusEnum.all);

  const ordersMids = useMemo(() => orders?.map((order) => order.mId), [orders]);

  const {
    orders: subOrders,
    loading: subOrdersLoading,
    error: subOrdersError,
  } = useGetOrdersForEntities(ordersMids, GetOrderEnum.Resource, TaskStatusEnum.all);

  const { blocksWithMdf } = useGetBlocksWithMdf(blocks, mdfs, mdfsSeparated);
  const { ordersWithMdf } = useGetOrdersWithMdf(orders, mdfs, mdfsSeparated);
  const { ordersWithMdf: subOrdersWithMdf } = useGetOrdersWithMdf(subOrders, mdfs, mdfsSeparated);

  const {
    relationMembers,
    relationsLoading,
    relationsError,
    contacts,
    contactsLoading,
    contactsError,
  } = useGetContactsAndRelationMembers(blocksWithMdf, ordersWithMdf, subOrdersWithMdf);

  if (loading || ordersLoading || subOrdersLoading || relationsLoading || contactsLoading)
    return (
      <LoadingWrapper>
        <LoadingIndicator />
      </LoadingWrapper>
    );

  if (error || ordersError || subOrdersError || relationsError || contactsError) {
    const errors = {
      error: error ?? null,
      ordersError: ordersError ?? null,
      subOrdersError: subOrdersError ?? null,
      relationsError: relationsError ?? null,
      contactsError: contactsError ?? null,
    };
    return <ErrorView errors={errors} />;
  }

  return (
    <PrintViewWrapper>
      <PdfViewer>
        <PlanningPrintDoc
          blocks={blocksWithMdf}
          orders={ordersWithMdf}
          mdfsSeparated={mdfsSeparated}
          subOrders={subOrdersWithMdf}
          relationMembers={relationMembers}
          contacts={contacts}
        />
      </PdfViewer>
    </PrintViewWrapper>
  );
};

const PlanningPrint = ({ isDialogOpen, onCloseDialog, blocks }: Props) => {
  return (
    <Dialog container={undefined} open={isDialogOpen} onClose={onCloseDialog}>
      <View blocks={blocks} />
    </Dialog>
  );
};

export default PlanningPrint;
