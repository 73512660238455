import { CSSProperties } from 'react';
import { flexRender } from '@tanstack/react-table';

import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import { TableCell } from 'components/dataTable/Table';
import { parseCellId } from 'features/grids/common/utils/cellUtils';
import { cellKeyboardShortcuts } from 'features/grids/common/utils/keyboardUtils';
import { HStack } from 'layouts/box/Box';

import { DataTableCell, typedMemo } from '../types';

const CellSkeleton = () => <div style={{ height: '20px', minWidth: '20px', width: '100%' }} />;

function Cell<T>({
  cell,
  row,
  isSkeletonRow,
  table,
  staticColumnIndex,
}: Readonly<DataTableCell<T>>) {
  const column = cell.column;
  const { enableKeyboardShortcuts } = table.options;

  const styles: CSSProperties = {
    // Background for placeholder and aggregated cells
    background:
      cell.getIsPlaceholder() || cell.getIsAggregated()
        ? // eslint-disable-next-line max-len
          'repeating-linear-gradient(45deg, rgba(0,0,0,0.2), rgba(0,0,0,0.2) 2px, transparent 2px, transparent 4px)'
        : 'revert-layer',
    minWidth: `max(calc(var(--${'col'}-${parseCellId(column.id)}-size) * 1px), ${
      column.columnDef.minSize ?? 30
    }px)`,
    width: `calc(var(--${'col'}-${parseCellId(column.id)}-size) * 1px)`,
    flex: `var(--${'col'}-${parseCellId(column.id)}-size) 0 auto`,
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTableCellElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    cellKeyboardShortcuts({
      cellValue: cell.getValue<string>(),
      event,
      table,
      row,
    });
  };

  return (
    <TableCell
      style={{ ...styles }}
      onKeyDown={handleKeyDown}
      tabIndex={enableKeyboardShortcuts ? 0 : undefined}
      data-index={staticColumnIndex}
    >
      {isSkeletonRow ? (
        <CellSkeleton />
      ) : (
        <>
          {cell.getIsGrouped() ? (
            <HStack
              justifyContent="flex-start"
              {...{
                onClick: row.getToggleExpandedHandler(),
                style: {
                  cursor: row.getCanExpand() ? 'pointer' : 'normal',
                },
              }}
            >
              {row.getIsExpanded() ? <ArrowDown /> : <ArrowRight />}{' '}
              {flexRender(cell.column.columnDef.cell, {
                ...cell.getContext(),
              })}{' '}
              ({row.subRows.length})
            </HStack>
          ) : cell.getIsAggregated() ? (
            <HStack
              justifyContent="flex-start"
              {...{
                onClick: row.getToggleExpandedHandler(),
                style: {
                  cursor: row.getCanExpand() ? 'pointer' : 'normal',
                  width: '100%',
                  height: '100%',
                },
              }}
            >
              {flexRender(
                cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell,
                cell.getContext(),
              )}
            </HStack>
          ) : cell.getIsPlaceholder() ? null : (
            flexRender(cell.column.columnDef.cell, {
              ...cell.getContext(),
            })
          )}
        </>
      )}
    </TableCell>
  );
}

export const MemoTableCell = typedMemo(Cell);
