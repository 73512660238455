import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ColorVariants } from 'types';

import { ButtonProps } from './Button';

const variantOptions = (colors: ColorVariants) => ({
  discreet: {
    border: '1px solid transparent',
  },
  outlined: {
    border: `1px solid ${colors.buttonBorderOutlined}`,
  },
  contained: {
    border: 'none',
  },
  dashed: {
    border: `1px dashed ${colors.buttonBorderOutlined}`,
  },
});

const usageOptions = (colors: ColorVariants) => ({
  text: {
    color: colors.mediumEmphasis,
    backgroundColor: 'transparent',
    hover: `${colors.outlineButtonOnHover}`,
    iconColor: colors.iconInactive,
  },
  outlined: {
    color: colors.highEmphasis,
    backgroundColor: `${colors.blackHoverOverlay}`,
    hover: `${colors.outlineButtonOnHover}`,
    iconColor: colors.iconInactive,
  },
  cta: {
    color: colors.whiteHighEmphasis,
    backgroundColor: `${colors.buttonBackgroundHighEmphasis}`,
    hover: `${colors.buttonBackgroundHighEmphasisHover}`,
    iconColor: colors.iconSelectedWhite,
  },
  significant: {
    color: colors.whiteHighEmphasis,
    backgroundColor: `${colors.buttonBackgroundSignificantAction}`,
    hover: `${colors.buttonBackgroundSignificantActionHover}`,
    iconColor: colors.iconSelectedWhite,
  },
  warning: {
    color: colors.blackHighEmphasis,
    backgroundColor: `${colors.buttonBackgroundWarning}`,
    hover: `${colors.buttonBackgroundWarningHover}`,
    iconColor: colors.blackMediumEmphasis,
  },
  danger: {
    color: colors.whiteHighEmphasis,
    backgroundColor: `${colors.buttonBackgroundDangerousAction}`,
    hover: `${colors.buttonBackgroundDangerousActionHover}`,
    iconColor: colors.iconSelectedWhite,
  },
  story: {
    color: colors.whiteHighEmphasis,
    backgroundColor: `${colors.statusStory}`,
    hover: `${colors.buttonBackgroundStoryHover}`,
    iconColor: colors.iconSelectedWhite,
  },
  pitch: {
    color: colors.whiteHighEmphasis,
    backgroundColor: `${colors.statusPitch}`,
    hover: `${colors.statusPitchHover}`,
    iconColor: colors.highEmphasis,
  },
});

export const StyledButton = styled('button')<ButtonProps>`
  ${({ theme }) => theme.typography.dina.button};
  width: 100%;
  height: 40px;
  border-radius: 6px;
  padding-inline: 8px;
  background-color: inherit;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  gap: 4px;
  overflow: hidden;
  transition: all 0.2s ease-out;
  user-select: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  position: relative;

  :focus-visible {
    outline: 1px solid ${({ theme }) => theme.palette.dina.onFocus};
    outline-offset: -1px;
    filter: brightness(1.1);
  }

  svg {
    min-height: 14px;
    min-width: 14px;
    path {
      fill-opacity: 1;
    }
  }

  ${({ variant = 'contained', theme }) => {
    const option = variantOptions(theme.palette.dina)[variant];
    return (
      option &&
      css`
        border: ${option.border};
      `
    );
  }};

  ${({ usage = 'cta', theme }) => {
    const option = usageOptions(theme.palette.dina)[usage];

    return (
      option &&
      css`
        background-color: ${option.backgroundColor};
        color: ${option.color};
        border: ${usage === 'text' && '1px solid transparent'};
        :hover {
          background-color: ${option.hover};
          ${usage === 'text' && `border: 1px solid ${theme.palette.dina.buttonBorderOutlined}`}
        }
        :active {
          background-color: ${option.backgroundColor};
        }
        svg {
          path {
            fill: ${option.iconColor};
          }
        }
      `
    );
  }};

  ${({ dark, theme }) =>
    dark &&
    css`
      color: ${theme.palette.dina.blackHighEmphasis};
      svg {
        path {
          fill: ${theme.palette.dina.blackHighEmphasis};
        }
      }
    `};

  ${({ selected, theme }) =>
    selected &&
    css`
      background-color: ${theme.palette.dina.onSelected};
      color: ${theme.palette.dina.highEmphasis};
      border: none;
      :hover {
        border: none;
        background-color: ${theme.palette.dina.onSelected};
        filter: brightness(0.92);
      }
      :focus-visible {
        border: 1px solid ${theme.palette.dina.blackMediumEmphasis};
      }
      svg {
        path {
          fill: ${theme.palette.dina.highEmphasis};
        }
      }
    `}

  ${({ align }) =>
    align === 'left' &&
    css`
      justify-content: flex-start;
      text-align: left;
    `}

  ${({ required, theme }) =>
    required &&
    css`
      border: 1px solid ${theme.palette.dina.statusWarning};
    `}

  ${({ autoWidth }) =>
    autoWidth &&
    css`
      width: auto;
      flex: 0 0 auto;
      padding: 0 20px;
    `}

  ${({ width }) =>
    width &&
    css`
      flex: 0 1 auto;
      width: ${typeof width === 'number' ? `${width}px` : width};
    `}

     ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}

  ${({ padding }) =>
    padding !== undefined &&
    css`
      padding: 0 ${padding}px;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.65;
      pointer-events: none;
    `}
`;

export const DisableWrapper = styled('span')`
  width: fit-content;
`;
