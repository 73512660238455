import keyBy from 'lodash/keyBy';

import { hasPermission, shouldFilterField } from 'features/mdf/mdf-utils';
import { MiniMember, OrderWithMdf } from 'types/forms/forms';
import { FieldTypeEnum } from 'types/graphqlTypes';

const getRelationFieldsFromTasks = (
  tasksWithMdf: OrderWithMdf[],
  groups: string[],
): MiniMember[] => {
  const relationMap = new Map<string, MiniMember>();

  for (const task of tasksWithMdf) {
    const { mdf, metadata } = task;
    if (!mdf?.fields || !mdf.views?.default) continue;

    const settingsMap = keyBy(mdf.views.default, 'fieldId');

    for (const field of mdf.fields) {
      if (
        field.type !== FieldTypeEnum.relation ||
        !shouldFilterField(
          field,
          settingsMap,
          settingsMap,
          true,
          hasPermission(mdf.permissions?.read[field.fieldId], groups),
        )
      )
        continue;

      const fieldValue = (metadata[field.fieldId] as MiniMember[]) || [];
      for (const member of fieldValue) {
        if (!relationMap.has(member.id)) {
          relationMap.set(member.id, member);
        }
      }
    }
  }

  return Array.from(relationMap.values());
};

export default getRelationFieldsFromTasks;
