import { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { Skeleton } from '@material-ui/lab';
import UserCtx from 'contexts/UserContext';
import configCtx from 'contexts/configContext';
import { useUpdateMdf } from 'api/mdf/useUpdateMdf';
import { useUpdatePrompts } from 'api/config/useUpdatePrompts';
import { useAuthContext } from 'contexts/AuthContext';
import { tours } from 'store';
import GET_METADATA_FORMS from 'operations/queries/getMetadataForms';
import GET_SETTINGS from 'operations/queries/getSettings';
import GET_POLICY from 'operations/queries/getPolicy';
import GET_PLATFORMS from 'operations/queries/getPlatforms';
import GET_CONFIGS from 'operations/queries/getConfigs';
import GET_RUNDOWN_TEMPLATES from 'operations/queries/getAllRundownTemplates';
import UPDATE_RUNDOWN_SYNC from 'operations/mutations/updateRundownSync';
import AppMenu from 'components/appMenu';
import Popover from 'components/popover/Popover';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import Avatar from 'components/avatar/Avatar';
import Dialog from 'components/dialog';
import TourPopup from 'components/tourPopup';
import useToast from 'components/toast/useToast';

import {
  useIsOpenConfirmDialog,
  useChangedGeneralSystemSettings,
  useChangedMetadataField,
  useChangedInstanceFields,
  useNewMetadataFieldId,
  useChangedRundownGridViews,
  useChangedRundownGridViewColumns,
  useChangedLinkFields,
  useNewLinkFieldId,
} from './atoms';
import {
  useChangedRows,
  useChangedRundownPermissions,
  useChangedMdfs,
  useChangedActions,
  useChangedPlugins,
  useChangedIntegrationWebhooks,
  useChangedPrompts,
  useChangedPanels,
  useSettingsOpen,
  useChangedProfileMetadata,
  useChangedProfilePicture,
  useChangedProfileTeams,
  useChangedProfileDepartments,
  useChangedEditorCommands,
  useChangedProviderNotificationSettings,
  useChangedUserSettings,
} from './atomsTs';

import useGetLinks from './hooks/useGetLinks';
import useGetWebhooks from './hooks/useGetWebhooks';
import useUpdateGroup from './hooks/useUpdateGroup';
import useUpdateUserSettings from './hooks/useUpdateUserSettings';
import useUpdateProfile from './hooks/useUpdateUserProfile';
import useUpdateSystemSettings from './hooks/useUpdateSystemSettings';
import useUpdateFormMetadataField from './hooks/useUpdateFormMetadataField';
import useUpdateRundownGridViews from './hooks/useUpdateRundownGridViews';
import useUpdateEditorCommands from './hooks/useUpdateEditorCommands';
import { useUpdateLinks } from './hooks/useUpdateLinks';
import { useUpdatePanels } from './hooks/useUpdatePanels';
import { useUpdateIntegration } from 'api/config/useUpdateIntegration';
import useGetEditorCommands from './hooks/useGetEditorCommands';
import Settings from './settings-view';

import { ProfileWrapper, SettingsWrapper, LoadingWrapper } from './settings-styled';
import { IntegrationEnum } from 'types/graphqlTypes';
import useUpdateProviderNotifications from './hooks/useUpdateProviderNotifications';

const linearPlatform = {
  mId: 'platform',
  mRefId: 'linear',
  mTitle: 'linear',
  mType: 'platform',
  __typename: 'MemberType',
};

const SettingsContainer = ({ avatar, username, userId }) => {
  const { mId } = useContext(UserCtx);
  const { kanbanBoardStates } = useContext(configCtx);
  const { toast, errorToast } = useToast();
  const { updateMdf } = useUpdateMdf();
  const { updateIntegration } = useUpdateIntegration();
  const { updatePrompts } = useUpdatePrompts();
  const [updateRundownSync] = useMutation(UPDATE_RUNDOWN_SYNC);
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [settingOpen, setSettingOpen] = useSettingsOpen(false);
  const [disabled, setDisabled] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [changedRows, setChangedRows] = useChangedRows();
  const [, setIsOpenConfirmDialog] = useIsOpenConfirmDialog();
  const [changedGeneralSystemSettings, setChangedGeneralSystemSettings] =
    useChangedGeneralSystemSettings();
  const [changedMetadataField, setChangedMetadataField] = useChangedMetadataField();
  const [newMetadataFieldId, setNewMetadataFieldId] = useNewMetadataFieldId();
  const [changedLinkFields, setChangedLinkFields] = useChangedLinkFields();
  const [changedEditorCommands, setChangedEditorCommands] = useChangedEditorCommands();
  const [changedMdfs, setChangedMdfs] = useChangedMdfs();
  const [changedActions, setChangedActions] = useChangedActions();
  const [changedPlugins, setChangedPlugins] = useChangedPlugins();
  const [changedWebhook, setChangedWebhook] = useChangedIntegrationWebhooks();
  const [changedPrompts, setChangedPrompts] = useChangedPrompts();
  const [, setNewLinkFieldId] = useNewLinkFieldId();
  const [changedUserSettings, setChangedUserSettings] = useChangedUserSettings();
  const [changedRP, setChangedRP] = useChangedRundownPermissions();
  const [changedProfileMetadata, setChangedProfileMetadata] = useChangedProfileMetadata();
  const [changedProfilePicture, setChangedProfilePicture] = useChangedProfilePicture();
  const [changedProfileTeams, setChangedProfileTeams] = useChangedProfileTeams();
  const [changedProfileDepts, setChangedProfileDepts] = useChangedProfileDepartments();
  const [changedProviderNotificationSettings, setChangedProviderNotificationSettings] =
    useChangedProviderNotificationSettings();
  const { update: updateProviderNotificationSettings } = useUpdateProviderNotifications();

  const [updatePermissions, loadingUpdatePermissions] = useUpdateGroup();
  const [updateLinks, loadingLinks] = useUpdateLinks();
  const [updateSystemSettings, loadingUpdateSystemSettings] = useUpdateSystemSettings();
  const [updateMetadataField, loadingUpdateMetadataField] = useUpdateFormMetadataField();
  const [updateUserSettings, loadingUpdateUserSettings] = useUpdateUserSettings();
  const { loading: loadingUpdateEditorCommands, updateEditorCommands } = useUpdateEditorCommands();
  const { updateProfileMetadata, updateProfilePicture, updateProfileTeamsOrDepts } =
    useUpdateProfile();
  const [updateRundownGridViews, loadingUpdateRundownGridViews] = useUpdateRundownGridViews();
  const [updatePanels, loadingUpdatePanels] = useUpdatePanels();
  const [changedInstanceFields, setChangedInstanceFields] = useChangedInstanceFields();
  const [changedRundownGridViews, setChangedRundownGridViews] = useChangedRundownGridViews();
  const [changedRundownGridViewColumns, setChangedRundownGridViewColumns] =
    useChangedRundownGridViewColumns();
  const [changedPanels, setChangedPanels] = useChangedPanels();
  const notificationRef = useRef();
  const { logout } = useAuthContext();

  useEffect(() => {
    const hasValidProviderInWebhook = Object.values(changedWebhook).some(
      (hook) => hook.mProvider !== '' && hook.mProvider !== null,
    );
    if (
      changedGeneralSystemSettings.length !== 0 ||
      changedRows.length !== 0 ||
      changedMetadataField.length !== 0 ||
      newMetadataFieldId !== '' ||
      changedInstanceFields.length ||
      changedLinkFields.length ||
      changedEditorCommands.length ||
      Object.values(changedMdfs).length ||
      Object.values(changedActions).length ||
      (Object.values(changedWebhook).length && hasValidProviderInWebhook) ||
      Object.values(changedPlugins).length ||
      changedPrompts?.length ||
      changedRundownGridViews.length !== 0 ||
      changedRundownGridViewColumns.length !== 0 ||
      changedUserSettings.length ||
      changedPanels.length ||
      changedRP.length ||
      changedProfileMetadata ||
      changedProfilePicture ||
      changedProfileTeams ||
      changedProfileDepts ||
      changedProviderNotificationSettings.length
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    changedGeneralSystemSettings,
    changedRows,
    changedMetadataField,
    newMetadataFieldId,
    changedInstanceFields,
    changedLinkFields,
    changedEditorCommands,
    changedMdfs,
    changedActions,
    changedPlugins,
    changedWebhook,
    changedPrompts,
    changedRundownGridViews,
    changedRundownGridViewColumns,
    changedUserSettings,
    changedPanels,
    changedRP,
    changedProfileMetadata,
    changedProfilePicture,
    changedProfileTeams,
    changedProfileDepts,
    changedProviderNotificationSettings,
  ]);

  const { data, error, loading } = useQuery(GET_POLICY, {
    variables: {
      input: {
        mId: 'groupPolicy',
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: systemSettings,
    loading: systemSettingsLoading,
    error: systemSettingsError,
  } = useQuery(GET_SETTINGS, {
    variables: {
      generalSettingsInput: { mId: 'settings', mRefId: 'general' },
      userSettingsInput: { mId: 'settings', mRefId: mId },
    },
    fetchPolicy: 'cache-and-network',
  });

  const [links, linksLoading, linksError] = useGetLinks();
  const [webhooks, webhooksLoading, webhooksError] = useGetWebhooks();
  useGetEditorCommands();

  const {
    data: metadataForms,
    loading: metadataFormsLoading,
    error: metadataFormsError,
  } = useQuery(GET_METADATA_FORMS, {
    variables: {
      input: {
        mId: 'form',
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const mappedPlatformValues = (platform) => {
    const accounts = platform?.mProperties?.accounts || [];
    return {
      mId: 'platform',
      mRefId: platform?.mTitle,
      mTitle: platform?.mTitle,
      mType: 'platform',
      accounts,
    };
  };

  const {
    data: platforms,
    error: platformsError,
    loading: platformsLoading,
  } = useQuery(GET_PLATFORMS, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: rundowns,
    error: rundownsError,
    loading: rundownsLoading,
  } = useQuery(GET_RUNDOWN_TEMPLATES, {
    mId: '',
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: panels,
    error: panelsError,
    loading: panelsLoading,
  } = useQuery(GET_CONFIGS, {
    variables: {
      input: {
        mId: 'externalurl',
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const platformsData = platforms?.getPlatforms
    ?.filter((p) => p.id !== 'linear')
    .map((p) => mappedPlatformValues(p));

  const { getMemberFromId } = data;

  const { getMetadataForms: forms } = metadataForms;
  const form = forms?.find((f) => f.mRefId === 'form-00');

  const handleClose = () => {
    setUserAnchorEl(null);
  };

  const onLogout = async () => {
    await logout(userId);
  };

  const handleCloseSettings = () => {
    if (!disabled) {
      setIsOpenConfirmDialog(true);
    } else {
      onClose();
    }
  };

  const handlePermissions = () => {
    updatePermissions(getMemberFromId);
  };

  const onClose = () => {
    setSettingOpen(false);
  };

  const onSave = async () => {
    setIsSaving(true);
    const updates = [];
    if (changedRows.length > 0) {
      handlePermissions();
    }
    if (changedGeneralSystemSettings.length > 0) {
      updates.push(updateSystemSettings(systemSettings));
    }

    if (changedMetadataField.length > 0 || changedInstanceFields.length > 0) {
      updates.push(updateMetadataField(form, [linearPlatform, ...platformsData]));
    }

    if (changedLinkFields.length) {
      updates.push(updateLinks());
    }

    if (changedEditorCommands.length) updates.push(updateEditorCommands());

    if (Object.values(changedMdfs).length) {
      for (const changedMdf of Object.values(changedMdfs)) {
        updates.push(updateMdf(changedMdf));
      }
    }

    if (Object.values(changedActions).length) {
      for (const changedAction of Object.values(changedActions)) {
        await updateIntegration({
          mRefId: changedAction.mRefId,
          integrationType: IntegrationEnum.CustomAction,
          externalId: changedAction.externalId,
          mTitle: changedAction.mTitle,
          auth: changedAction.auth,
          mDescription: changedAction.mDescription,
          mActive: changedAction.mActive,
          mTypes: changedAction.mTypes,
          connectedIds: changedAction.connectedIds,
          endpoint: changedAction.endpoint,
          iconUrl: changedAction.iconUrl,
        });
      }
    }

    if (Object.values(changedPlugins).length) {
      for (const changedPlugin of Object.values(changedPlugins)) {
        await updateIntegration({
          mRefId: changedPlugin.mRefId,
          integrationType: IntegrationEnum.SearchPlugin,
          externalId: changedPlugin.externalId,
          mTitle: changedPlugin.mTitle,
          auth: changedPlugin.auth,
          mDescription: changedPlugin.mDescription,
          mActive: changedPlugin.mActive,
          mTypes: changedPlugin.mTypes,
          connectedIds: changedPlugin.connectedIds,
          endpoint: changedPlugin.endpoint,
          iconUrl: changedPlugin.iconUrl,
        });
      }
    }

    if (Object.values(changedWebhook).length) {
      for (const changedHook of Object.values(changedWebhook)) {
        const integrationType =
          changedHook?.mId == 'endpoint' ? IntegrationEnum.EndPoint : IntegrationEnum.Webhook;

        await updateIntegration({
          mRefId: changedHook.mRefId,
          integrationType: integrationType,
          externalId: changedHook.externalId,
          mTitle: changedHook.mTitle,
          auth: changedHook.auth,
          mDescription: changedHook.mDescription,
          mProvider: changedHook.mProvider,
          mActive: changedHook.mActive,
          mTypes: changedHook.mTypes,
          connectedIds: changedHook.connectedIds,
          endpoint: changedHook.endpoint,
          iconUrl: changedHook.iconUrl,
        });
      }
    }

    if (changedPrompts?.length) {
      updates.push(updatePrompts(changedPrompts));
    }

    if (changedRundownGridViews.length > 0 || changedRundownGridViewColumns.length > 0) {
      updates.push(
        updateRundownGridViews(form, changedRundownGridViewColumns, changedRundownGridViews),
      );
    }

    if (changedUserSettings.length) {
      updates.push(updateUserSettings(systemSettings));
    }

    if (changedPanels.length) {
      updates.push(updatePanels());
    }

    if (changedRP.length) {
      const promises = changedRP.map((cRP) => {
        const input = {
          ...cRP,
          mPayload: [
            {
              crudAction: 'UPDATE',
            },
          ],
        };
        return updateRundownSync({
          variables: { input },
        });
      });
      await Promise.all(promises);
    }

    if (changedProfileMetadata) {
      updates.push(updateProfileMetadata(mId, changedProfileMetadata));
    }

    if (changedProfilePicture) {
      updates.push(updateProfilePicture(changedProfilePicture));
    }

    if (changedProfileTeams) {
      updates.push(updateProfileTeamsOrDepts(mId, 'team', changedProfileTeams));
    }

    if (changedProfileDepts) {
      updates.push(updateProfileTeamsOrDepts(mId, 'department', changedProfileDepts));
    }

    if (changedProviderNotificationSettings.length) {
      updates.push(updateProviderNotificationSettings());
    }

    try {
      await Promise.all(updates).finally(() => setIsSaving(false));
      toast({ title: 'Successfully saved changes', type: 'success' });
      discardChanges();
    } catch (err) {
      errorToast(err);
    }
  };

  const onSaveAndClose = () => {
    setIsOpenConfirmDialog(false);
    onSave();
    onClose();
  };

  const discardChanges = () => {
    setIsOpenConfirmDialog(false);
    setChangedGeneralSystemSettings([]);
    setChangedMetadataField([]);
    setChangedRows([]);
    setNewMetadataFieldId('');
    setChangedInstanceFields([]);
    setChangedLinkFields([]);
    setChangedEditorCommands([]);
    setChangedMdfs({});
    setChangedActions({});
    setChangedPlugins({});
    setChangedWebhook({});
    setChangedPrompts(null);
    setNewLinkFieldId('');
    setChangedRundownGridViews([]);
    setChangedRundownGridViewColumns([]);
    setChangedUserSettings([]);
    setChangedPanels([]);
    setChangedRP([]);
    setChangedProfileMetadata(null);
    setChangedProfilePicture(null);
    setChangedProfileTeams(null);
    setChangedProfileDepts(null);
    setChangedProviderNotificationSettings([]);
  };

  const onDiscardAndClose = () => {
    discardChanges();
    onClose();
  };

  const onCloseConfirmDialog = () => setIsOpenConfirmDialog(false);

  if (
    error ||
    systemSettingsError ||
    metadataFormsError ||
    platformsError ||
    rundownsError ||
    linksError ||
    webhooksError ||
    panelsError
  ) {
    return <div>no data</div>;
  }

  const loadingDependencies =
    loadingUpdatePermissions ||
    loadingUpdateSystemSettings ||
    loadingUpdateMetadataField ||
    loadingUpdateRundownGridViews ||
    loadingLinks ||
    rundownsLoading ||
    loadingUpdateEditorCommands ||
    loadingUpdateUserSettings ||
    loadingUpdatePanels;

  const bootstrappingDialog =
    loading ||
    systemSettingsLoading ||
    metadataFormsLoading ||
    platformsLoading ||
    linksLoading ||
    webhooksLoading ||
    panelsLoading;

  if (data && systemSettings && metadataForms && platforms && panels)
    return (
      <>
        <TourPopup
          position="bottom"
          anchor={notificationRef}
          tourStep={tours.MainTour.steps.Mobile}
          tourId={tours.MainTour.id}
        />
        <div ref={notificationRef}>
          <Avatar
            size={32}
            imageKey={avatar}
            onClick={(e) => {
              setUserAnchorEl(e.currentTarget);
            }}
            variant="user"
          />
        </div>

        {userAnchorEl && (
          <Popover
            style={{ marginTop: '24px', marginLeft: '-16px' }}
            anchorEl={userAnchorEl}
            position="center"
            onClose={() => {
              setUserAnchorEl(null);
            }}
          >
            <ProfileWrapper>
              <AppMenu
                avatar={avatar}
                username={username}
                onClose={handleClose}
                onLogout={onLogout}
                key="app-menu"
                setSettingOpen={setSettingOpen}
              />
            </ProfileWrapper>
          </Popover>
        )}
        <Dialog open={settingOpen} disableEnforceFocus>
          <SettingsWrapper id="settings-wrapper">
            {loadingDependencies && (
              <LoadingWrapper>
                <LoadingIndicator />
              </LoadingWrapper>
            )}
            <Settings
              groupPolicies={getMemberFromId}
              systemSettings={systemSettings}
              form={form}
              links={links}
              webhooks={webhooks}
              onClose={onClose}
              onSaveAndClose={onSaveAndClose}
              onDiscardAndClose={onDiscardAndClose}
              onSave={onSave}
              handleCloseSettings={handleCloseSettings}
              instanceTypes={[linearPlatform, ...platformsData]}
              rundowns={rundowns}
              disabled={disabled}
              isSaving={isSaving}
              onCloseConfirmDialog={onCloseConfirmDialog}
              kanbanStates={kanbanBoardStates}
              panels={panels.getMemberFromId}
            />
          </SettingsWrapper>
        </Dialog>
      </>
    );

  if (bootstrappingDialog) {
    return <Skeleton animation="wave" variant="circle" width={32} height={32} />;
  }

  return null;
};

SettingsContainer.propTypes = {
  avatar: PropTypes.string,
  username: PropTypes.string,
  userId: PropTypes.string,
};

SettingsContainer.defaultProps = {
  avatar: undefined,
  username: undefined,
  userId: undefined,
};

export default SettingsContainer;
