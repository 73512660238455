import { useEffect, useMemo, useRef, useState } from 'react';
import { atom, useAtom } from 'jotai';

import { useCreateInstance } from 'api/instance/useCreateInstance';
import ICONS from 'assets/icons/icons';
import { publishingPoints as publishingPointIcons } from 'assets/icons/publishingPoints';
import { Button } from 'components/buttons';
import { isPlatformArray } from 'components/editMdfDialog/components/EditOrderFormProperties';
import InstanceSelector from 'components/menu/createInstanceMenu/MenuContent/SingleInstance';
import useFilterPlatforms from 'hooks/useFilterPlatforms';
import useGetPlatforms from 'hooks/useGetPlatforms';
import { useSelectedDate } from 'screens/story/components/header/atoms';
import { useCurrentTabType } from 'store/tabs';
import { getPlatform, PlatformData } from 'utils/instance/platform';

import {
  ArrowDownIcon,
  ButtonText,
  IconButton,
  Popover,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogContentText,
  StyledDialogTitle,
  TextField,
  Wrapper,
} from './styled';

export interface AddInstance {
  text: string | null;
  onCancel?: () => void;
}

const addInstanceAtom = atom<AddInstance>({ text: null });

export const useAddInstanceDialog = () => useAtom(addInstanceAtom);

export function AddInstanceDialog() {
  const [currentTabType] = useCurrentTabType();
  const [open, setOpen] = useState(false);
  const [state, setState] = useAddInstanceDialog();
  const [text, setText] = useState('');
  const [selectedDate] = useSelectedDate();
  const { platforms: data, platformVariants } = useGetPlatforms(
    selectedDate ? new Date(selectedDate) : new Date(),
  );
  const { createInstance } = useCreateInstance();
  const { filterPlatforms } = useFilterPlatforms();
  const [selectedPlatform, setSelectedPlatform] = useState<PlatformData | undefined>();

  const isStoryTab = currentTabType === 'story';

  const [openPopup, setOpenPopup] = useState(false);
  const popupRef = useRef<HTMLDivElement | null>(null);

  const platforms = useMemo(() => {
    if (isPlatformArray(data)) {
      return data;
    }
    return [];
  }, [data]);

  const platform = useMemo(
    () => getPlatform(platforms, selectedPlatform?.platform ?? '', selectedPlatform?.platformKind),
    [selectedPlatform, platforms],
  );

  const platformVariant = useMemo(() => {
    const variant = selectedPlatform?.account.variant ?? selectedPlatform?.platform;
    return variant ? platformVariants[variant] : undefined;
  }, [selectedPlatform, platformVariants]);

  useEffect(() => {
    if (state.text !== null) {
      setOpen(true);
      setText(state.text);
      setSelectedPlatform(undefined);
    }
  }, [state]);

  const onConfirm = async (isCancel: boolean) => {
    if (isCancel) {
      setState({ text: null });
      state?.onCancel?.();
    } else {
      await createInstance(selectedPlatform, text, platform, platformVariant);
    }
    setOpen(false);

    setSelectedPlatform(undefined);
  };

  const handlePopupClose = (platformData: PlatformData) => {
    if (platformData?.platform) setSelectedPlatform(platformData);
    setOpenPopup(false);
  };

  const iconKey = selectedPlatform?.platformKind ?? selectedPlatform?.platform ?? 'default';
  const iconSrc = publishingPointIcons[iconKey];

  return (
    <StyledDialog open={open} onClose={() => onConfirm(true)} scroll="body" container={undefined}>
      <IconButton
        icon={ICONS.CLOSE_SMALL}
        onClick={() => onConfirm(true)}
        title="Close"
        height={24}
        width={24}
        iconClass={undefined}
      />
      <StyledDialogTitle>Create instance from text</StyledDialogTitle>
      <StyledDialogContent>
        <StyledDialogContentText>Choose Instance type</StyledDialogContentText>
        <Wrapper ref={popupRef}>
          <Button
            onClick={() => setOpenPopup(true)}
            variant="outlined"
            usage="outlined"
            width={300}
            style={{ gap: '4px' }}
            align="left"
          >
            <img src={iconSrc} alt="publishing-point" />
            <ButtonText>
              {selectedPlatform?.account?.accountTitle ?? 'Choose an instance type...'}
            </ButtonText>
            {!selectedPlatform && <ArrowDownIcon />}
          </Button>
          <Popover open={openPopup} onClose={handlePopupClose} anchorEl={popupRef.current}>
            <div>
              <InstanceSelector
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                instanceItems={filterPlatforms(platforms)}
                canScheduleInstance
                onClose={handlePopupClose}
                publishingAt={undefined}
              />
            </div>
          </Popover>

          <TextField
            variant="outlined"
            value={text}
            onChange={(e) => setText(e.target.value)}
            multiline
            autoFocus
            minRows={10}
            maxRows={10}
          />
        </Wrapper>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button
          onClick={() => void onConfirm(true)}
          variant="outlined"
          usage="outlined"
          width={120}
        >
          Cancel
        </Button>
        <Button
          disabled={!isStoryTab || !selectedPlatform?.platform}
          onClick={() => void onConfirm(false)}
          width={160}
        >
          Create new Instance
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}
