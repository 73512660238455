/* eslint-disable sort-imports */
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';

import ToolbarPositions from 'components/editor/constants/toolbarPositions';
import transientOptions from 'theme/helpers';

const commonStyle = css`
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface EmotionCSS {
  theme: Theme;
}

const readOnlyStyles = ({ theme }: EmotionCSS) => css`
  background: ${theme.palette.dina.dividerDark};
  pointer-events: none;
`;

const generalStyles = ({ theme }: EmotionCSS) => css`
  background: ${theme.palette.dina.blackHoverOverlay};
  pointer-events: all;
`;

const autoHeightStyles = css`
  height: auto;
  padding: 0;
  border: none;
`;

export const ToolbarWrapper = styled('div')`
  position: relative;
`;

interface ContainerProps {
  $readOnly: boolean;
  $disableGeneralToolbar?: boolean;
  $toolbarPosition?: string;
  $autoHeight?: boolean;
}
export const Container = styled('div', transientOptions)<ContainerProps>`
  ${commonStyle};
  ${({ $readOnly, $disableGeneralToolbar }) =>
    $readOnly || $disableGeneralToolbar ? readOnlyStyles : generalStyles};
  ${({ $toolbarPosition, theme }) => {
    if ($toolbarPosition === ToolbarPositions.BOTTOM)
      return css`
        border-top: 1px solid ${theme.palette.dina.dividerLight};
      `;
    if ($toolbarPosition === ToolbarPositions.TOP)
      return css`
        border-bottom: 1px solid ${theme.palette.dina.dividerLight};
      `;
    return '';
  }};
  ${({ $autoHeight }) => $autoHeight && autoHeightStyles};
`;

export const ToolbarContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  flex: 1;
  margin-left: -1px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const OuterWrapper = styled('div')`
  height: 40px;
  display: flex;
  align-items: center;
  flex: 1;
  margin-top: -1px;
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const InnerWrapper = styled('div')`
  display: flex;
  gap: 8px;
  flex: 1;
  padding: 0 8px;
  border-left: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const Empty = styled('div')`
  height: 40px;
  flex: 1;
  margin-top: -1px;
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;
