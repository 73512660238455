import keyBy from 'lodash/keyBy';

import { hasPermission, shouldFilterField } from 'features/mdf/mdf-utils';
import { MiniMember, OrderWithMdf } from 'types/forms/forms';
import { FieldTypeEnum, MemberTypeEnum } from 'types/graphqlTypes';

const getContactFieldsFromTasks = (tasksWithMdf: OrderWithMdf[], groups: string[]): string[] => {
  const contacts = new Set<string>();

  for (const task of tasksWithMdf) {
    const { mdf, metadata } = task;
    if (!mdf?.fields || !mdf.views?.default) continue;

    const settingsMap = keyBy(mdf.views.default, 'fieldId');

    for (const field of mdf.fields) {
      if (
        !shouldFilterField(
          field,
          settingsMap,
          settingsMap,
          true,
          hasPermission(mdf.permissions?.read[field.fieldId], groups),
        )
      )
        continue;

      if (field.type === FieldTypeEnum.user && field.filter?.includes('contact')) {
        const fieldValue = metadata[field.fieldId]?.toString();
        if (fieldValue) contacts.add(fieldValue);
      } else if (field.type === FieldTypeEnum.relation) {
        const fieldValue = metadata[field.fieldId] as MiniMember[] | undefined | null;

        if (!fieldValue) break;

        for (const member of fieldValue) {
          if (member.type === MemberTypeEnum.Contact) {
            contacts.add(member.id);
          }
        }
      }
    }
  }

  return Array.from(contacts);
};

export default getContactFieldsFromTasks;
