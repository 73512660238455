import { useRef } from 'react';
import { createScope, molecule, useMolecule } from 'jotai-molecules';

export const RundownScope = createScope<string | null>(null);

const rundownMolecule = molecule((_getMol, getScope) => {
  const scope = getScope(RundownScope);
  const currentEditingRef = scope ? useRef<string | null>(null) : undefined;

  return { currentEditingRef, scope };
});
export const useRundownMolecule = () => useMolecule(rundownMolecule);
