import Dialog from 'components/dialog';
import { initialValues } from 'components/editor/constants';
import variants from 'components/editor/constants/types/editorVariants';
import LoadingIndicator from 'components/loadingIndicator';
import PdfViewer from 'components/pdfViewer/PdfViewer';
import useContentResolver from 'hooks/useContentResolver';
import { Instance } from 'types';

import ErrorView from './components/ErrorView';
import InstancePrintDoc from './docs/InstancePrintDoc';
import useGetBlocksAndOrders from './hooks/useGetBlocksAndOrders';
import useGetContactsAndRelationMembers from './hooks/useGetContactsAndRelationMembers';

import { LoadingWrapper, PrintViewWrapper } from './styled';

interface InstancePrintProps {
  instance: Instance;
  isDialogOpen: boolean;
  onCloseDialog: () => void;
  clipDuration: string;
  scriptDuration: string;
  totalDuration: string;
}

const View = ({
  instance,
  clipDuration,
  scriptDuration,
  totalDuration,
}: Readonly<Omit<InstancePrintProps, 'isDialogOpen' | 'onCloseDialog'>>) => {
  const defaultData = initialValues(variants.GENERAL, undefined, undefined, false);
  const { data, loading, error } = useContentResolver(instance?.mContentKey);

  const {
    mdfsSeparated,
    blocksWithMdf,
    ordersWithMdf,
    blocksLoading,
    mdfsLoading,
    ordersLoading,
    mdfsError,
    blocksError,
    ordersError,
  } = useGetBlocksAndOrders(instance.mId);

  const {
    relationMembers,
    relationsLoading,
    relationsError,
    contacts,
    contactsLoading,
    contactsError,
  } = useGetContactsAndRelationMembers(blocksWithMdf, ordersWithMdf, []);

  const contentData = data ?? defaultData;

  if (
    loading ||
    mdfsLoading ||
    blocksLoading ||
    ordersLoading ||
    relationsLoading ||
    contactsLoading
  )
    return (
      <LoadingWrapper>
        <LoadingIndicator />
      </LoadingWrapper>
    );

  if (error || blocksError || mdfsError || ordersError || relationsError || contactsError) {
    const errors = {
      error: error ?? null,
      blocksError: blocksError ?? null,
      mdfsError: mdfsError ?? null,
      ordersError: ordersError ?? null,
      relationsError: relationsError ?? null,
      contactsError: contactsError ?? null,
    };

    return <ErrorView errors={errors} />;
  }

  return (
    <PrintViewWrapper>
      <PdfViewer>
        <InstancePrintDoc
          instance={instance}
          content={contentData}
          clipDuration={clipDuration}
          scriptDuration={scriptDuration}
          totalDuration={totalDuration}
          blocks={blocksWithMdf}
          orders={ordersWithMdf}
          mdfsSeparated={mdfsSeparated}
          relationMembers={relationMembers}
          contacts={contacts}
        />
      </PdfViewer>
    </PrintViewWrapper>
  );
};

function InstancePrint({
  instance,
  isDialogOpen,
  onCloseDialog,
  clipDuration,
  scriptDuration,
  totalDuration,
}: Readonly<InstancePrintProps>) {
  return (
    <Dialog container={null} open={isDialogOpen} onClose={onCloseDialog}>
      <View
        instance={instance}
        clipDuration={clipDuration}
        scriptDuration={scriptDuration}
        totalDuration={totalDuration}
      />
    </Dialog>
  );
}

export default InstancePrint;
