/* eslint-disable @typescript-eslint/no-use-before-define */
import { useCallback, useContext, useEffect, useMemo } from 'react';
import format from 'date-fns/format';
import { Dictionary } from 'lodash';
import keyBy from 'lodash/keyBy';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { BlockWithLabel } from 'api/mdfBlocks/types';
import UserContext from 'contexts/UserContext';
import { getSubMdf, hasPermission, shouldFilterField } from 'features/mdf/mdf-utils';
import useGetBlocksWithMdf from 'features/print/hooks/useGetBlocksWithMdf';
import useGetContactsAndRelationMembers from 'features/print/hooks/useGetContactsAndRelationMembers';
import useGetOrdersWithMdf from 'features/print/hooks/useGetOrdersWithMdf';
import { useGetOrdersForEntities } from 'screens/space/api/useGetOrdersAndForms';
import { User } from 'types';
import { Metadata, MiniMember, OrderWithMdf } from 'types/forms/forms';
import {
  FieldTypeEnum,
  GetOrderEnum,
  LayoutSettings,
  Mdf,
  MdfField,
  MemberType,
  MemberTypeEnum,
  TaskStatusEnum,
} from 'types/graphqlTypes';
import getMembersFromRelationField from 'utils/mdf/getMembersFromRelationField';

import useCopyText from './useCopyText';
import useGetMembersInfo from './useGetMembersInfo';

const emptyUserMeta = '{"email": "", "phone": ""}';

export const getUserContent = (member: MemberType | undefined, singleLine = false) => {
  let memberMeta: Metadata = {
    email: '',
    phone: '',
  };

  try {
    memberMeta = JSON.parse(member?.metadata ?? emptyUserMeta) as Metadata;
  } catch (e) {
    //
  }

  if (singleLine) {
    return `${member?.mTitle} [${member?.mType?.toUpperCase()}] Email: ${
      (memberMeta.email as string) ?? ''
    } Phone: ${(memberMeta.phone as string) ?? ''}`;
  }

  return `Name: ${member?.mTitle} [${member?.mType?.toUpperCase()}] \nEmail: ${
    (memberMeta.email as string) ?? ''
  } \nPhone: ${(memberMeta.phone as string) ?? ''}`;
};

export const getMdfTextContent = ({
  field,
  settingsMap,
  metadata,
  getMember,
  getMemberTitle,
  relationMembers = [],
  contacts = [],
}: {
  field: MdfField;
  settingsMap: Dictionary<LayoutSettings>;
  metadata: Metadata;
  getMemberTitle: (userId: string) => string | undefined;
  getMember: (userId: string) => User | undefined;
  relationMembers?: MemberType[];
  contacts?: MemberType[];
}) => {
  const label = settingsMap[field.fieldId].label;
  const fieldValue = metadata[field.fieldId]?.toString() ?? '';

  let content = '';

  switch (field.type) {
    case FieldTypeEnum.text:
    case FieldTypeEnum.number:
      content = `${label}: ${fieldValue} `;
      break;

    case FieldTypeEnum.link:
      content = `${label}: ${fieldValue} `;
      break;

    case FieldTypeEnum.user:
      {
        const user =
          getMember(fieldValue) ??
          (contacts.find((contact) => contact.mId === fieldValue) as User | undefined);
        const userName = getMemberTitle(fieldValue);
        const userValue = getUserContent(user);

        content = `${label}: \n${userValue ?? userName ?? ''} `;
      }
      break;

    case FieldTypeEnum.treechoice:
      content = `${label}: ${fieldValue.replace(/,/g, ' -> ')} `;
      break;

    case FieldTypeEnum.checkbox:
      {
        const value = Boolean(fieldValue) === true ? `[x] ${label}` : `[ ] ${label}`;
        content = `${label}: ${value} `;
      }
      break;

    case FieldTypeEnum.date:
      content = `${label}: ${format(new Date(fieldValue), 'MMM D YYYY, HH:mm:ss (Z)')} `;
      break;

    case FieldTypeEnum.multiplechoice:
      content = `${label}: ${fieldValue !== '' ? fieldValue.split(',').join(', ') : ''} `;

      break;

    case FieldTypeEnum.choice:
      content = `${label}: ${fieldValue ?? ''} `;
      break;

    case FieldTypeEnum.relation:
      {
        const actualFieldValue = (metadata[field.fieldId] as MiniMember[]) ?? [];
        const fieldMembers = getMembersFromRelationField(actualFieldValue, relationMembers);

        const relationValue = fieldMembers
          .map((fMember, index) => {
            if (
              [
                MemberTypeEnum.Contact,
                MemberTypeEnum.User,
                MemberTypeEnum.Department,
                MemberTypeEnum.Team,
              ].includes(fMember?.mType as MemberTypeEnum)
            ) {
              const member =
                getMember(fMember?.mId as string) ??
                contacts.find((contact) => contact.mId === fMember?.mId);
              const userValue = getUserContent(member, true);

              return `${userValue}${index === fieldMembers.length - 1 ? '' : ','}`;
            }

            return `${fMember?.mTitle ?? 'Untitled'} (${
              fMember?.mType?.toUpperCase() ?? 'Unknown type'
            })${index === fieldMembers.length - 1 ? '' : ','}`;
          })
          .join('\n');

        content = `${label}: \n${relationValue} `;
      }
      break;

    default:
      content = `${label}: ${fieldValue ?? ''} `;
      break;
  }

  return content;
};

export const getOrderContent = ({
  order,
  groups,
  getMemberTitle,
  getMember,
  index,
  subMdfs,
  subOrders,
  title = 'Task',
  relationMembers,
  contacts,
}: {
  order: OrderWithMdf;
  title?: string;
  getMemberTitle: (userId: string) => string | undefined;
  getMember: (userId: string) => User | undefined;
  groups: string[];
  index: number;
  subMdfs?: Mdf[];
  subOrders?: OrderWithMdf[];
  relationMembers?: MemberType[];
  contacts?: MemberType[];
}) => {
  const { metadata, mdf } = order;
  const subTypes = keyBy(subMdfs, (subMdf) => subMdf.label);

  const settingsMap = keyBy(mdf.views.default, (setting) => setting.fieldId);

  const visibleFields = mdf.fields?.filter((f) =>
    shouldFilterField(
      f,
      settingsMap,
      settingsMap,
      true,
      hasPermission(mdf?.permissions?.read[f.fieldId], groups),
    ),
  );

  const createdByUser = getMember(order.mCreatedById);
  const assigneeUser = getMember(order.mAssignee ?? '');

  const subOrdersContent: string = subOrders?.length
    ? subOrders
        .map((subOrder, subIndex) =>
          getOrderContent({
            order: subOrder,
            groups,
            index: subIndex,
            title: 'Sub task',
            getMemberTitle,
            getMember,
            relationMembers,
            contacts,
          }),
        )
        .join('\n') ?? ''
    : '';

  const orderInfo = `Created by: \n${getUserContent(createdByUser)}\nAssignee: \n${
    assigneeUser ? getUserContent(assigneeUser) : 'No assignee'
  }`;

  return `\n${title} ${index + 1}\n${orderInfo}\n${
    visibleFields
      ?.map((field) => {
        if (field.type === FieldTypeEnum.subtype) {
          const subMdf = getSubMdf(field, metadata, subTypes);

          return getBlockContent({
            fields: subMdf?.fields,
            layoutSettings: subMdf?.views.default,
            permissions: subMdf?.permissions,
            metadata,
            blockTitle: settingsMap[field.fieldId].label,
            groups,
            getMemberTitle,
            getMember,
            relationMembers,
            contacts,
          });
        }

        return getMdfTextContent({
          field,
          settingsMap,
          metadata,
          getMemberTitle,
          getMember,
          relationMembers,
          contacts,
        });
      })
      .join('\n') ?? ''
  }\n${subOrdersContent}`;
};

export const getBlockContent = ({
  fields,
  layoutSettings,
  metadata,
  permissions,
  subMdfs,
  blockTitle,
  orders,
  groups,
  getMemberTitle,
  getMember,
  relationMembers,
  contacts,
}: {
  metadata: Metadata;
  groups: string[];
  getMemberTitle: (userId: string) => string | undefined;
  getMember: (userId: string) => User | undefined;
  orders?: OrderWithMdf[];
  subMdfs?: Mdf[];
  fields?: MdfField[];
  layoutSettings?: LayoutSettings[];
  permissions?: Mdf['permissions'];
  blockTitle?: string;
  relationMembers?: MemberType[];
  contacts?: MemberType[];
}): string => {
  const subTypes = keyBy(subMdfs, (subMdf) => subMdf.label);

  const settingsMap = keyBy(layoutSettings, (setting) => setting.fieldId);

  const visibleFields = fields?.filter((f) =>
    shouldFilterField(
      f,
      settingsMap,
      settingsMap,
      true,
      hasPermission(permissions?.read[f.fieldId] as string[], groups),
    ),
  );

  const fieldContent = visibleFields
    ? visibleFields
        ?.map((field) => {
          if (field.type === FieldTypeEnum.subtype) {
            const subMdf = getSubMdf(field, metadata, subTypes);

            const subContent = getBlockContent({
              fields: subMdf?.fields,
              layoutSettings: subMdf?.views.default,
              permissions: subMdf?.permissions,
              metadata,
              blockTitle: settingsMap[field.fieldId].label,
              groups,
              getMemberTitle,
              getMember,
              relationMembers,
              contacts,
            });

            return `\n${subContent}`;
          }

          return getMdfTextContent({
            field,
            settingsMap,
            metadata,
            getMemberTitle,
            getMember,
            relationMembers,
            contacts,
          });
        })
        ?.join('\n') ?? ''
    : 'No options selected';

  const orderContent = orders?.length
    ? orders
        ?.map((order, index) => {
          return getOrderContent({ order, groups, getMemberTitle, index, subMdfs, getMember });
        })
        .join('\n')
    : '';

  return `${blockTitle}\n${fieldContent ?? 'No options selected'}${
    orders?.length ? `\nTasks${orderContent ? `\n${orderContent}` : ''}` : ''
  }\n`;
};

const useCopyPlanning = (blocks: BlockWithLabel[]) => {
  const { groups } = useContext(UserContext);
  const { getMemberTitle, getMember } = useGetMembersInfo();
  const { onCopy: copyToClipboard } = useCopyText();

  const { mdfs, mdfsSeparated, loading, error } = useGetMdfs({ all: true });

  const mIds = useMemo(() => blocks.map((block) => block.mRefId), [blocks]);

  const {
    orders,
    loading: ordersLoading,
    error: ordersError,
    refetch: refetchOrders,
  } = useGetOrdersForEntities(mIds, GetOrderEnum.Resource, TaskStatusEnum.all);

  useEffect(() => {
    refetchOrders().then(
      () => {},
      () => {},
    );
  }, [blocks, refetchOrders]);

  const { blocksWithMdf } = useGetBlocksWithMdf(blocks, mdfs, mdfsSeparated);
  const { ordersWithMdf } = useGetOrdersWithMdf(orders, mdfs, mdfsSeparated);

  const {
    relationMembers,
    relationsLoading,
    relationsError,
    contacts,
    contactsLoading,
    contactsError,
  } = useGetContactsAndRelationMembers(blocksWithMdf, ordersWithMdf, []);

  const onCopy = useCallback(async () => {
    if (
      ordersLoading ||
      loading ||
      relationsLoading ||
      contactsLoading ||
      !ordersWithMdf ||
      !blocksWithMdf ||
      ordersError ||
      error ||
      relationsError ||
      contactsError
    )
      return;

    const allContent = `${blocksWithMdf
      .map((block) => {
        const { mTitle, mRefId, mdf, metadata } = block;

        return getBlockContent({
          fields: mdf?.fields,
          metadata,
          groups,
          subMdfs: mdfsSeparated.subTypes,
          layoutSettings: mdf?.views?.default,
          permissions: mdf?.permissions,
          blockTitle: mTitle,
          orders: ordersWithMdf.filter((order) => order.mResourceId === mRefId),
          getMemberTitle,
          getMember,
          relationMembers,
          contacts,
        });
      })
      .join('\n')}`;

    await copyToClipboard(allContent, 'Items copied to clipboard');
  }, [
    blocksWithMdf,
    contacts,
    contactsError,
    contactsLoading,
    copyToClipboard,
    error,
    getMember,
    getMemberTitle,
    groups,
    loading,
    mdfsSeparated.subTypes,
    ordersError,
    ordersLoading,
    ordersWithMdf,
    relationMembers,
    relationsError,
    relationsLoading,
  ]);

  return { onCopy, loading: loading || ordersLoading || contactsLoading || relationsLoading };
};

export default useCopyPlanning;
