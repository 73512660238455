import { MouseEvent, useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';

import Text from 'components/text';
import useCreateConvo from 'hooks/useCreateConvo';
import useGetUser from 'hooks/useGetUser';
import { Box, VStack } from 'layouts/box/Box';
import { useIsMessageHubOpen, useSelectedConversationId } from 'store';
import { getUserIdFromLockedId } from 'utils/lock/lockToken';

import {
  ActionButtonsWrapper,
  CancelButton,
  Container,
  ForceUnlockButton,
  MessageContainer,
  Progress,
  ProgressText,
  SaveButton,
} from './styled';

interface Props {
  readLock: boolean;
  writeLock: boolean;
  lockedBy: string;
  isCancelled?: boolean;
  isSaving: boolean;
  //* Todo: change to required prop
  lockedId?: string;
  isCurrentUser?: boolean;
  onDone: () => Promise<void>;
  onCancel?: () => Promise<void>;
  onForceUnlock?: () => Promise<void>;
  collapsed?: boolean;
}

const LockedIndicator = ({
  readLock,
  writeLock,
  lockedBy,
  lockedId,
  isCurrentUser,
  isCancelled,
  isSaving,
  onDone,
  onCancel,
  onForceUnlock,
  collapsed,
}: Props) => {
  const { createConvo } = useCreateConvo();
  const [, setIsMessageHubOpen] = useIsMessageHubOpen();
  const [, setSelectedConvoId] = useSelectedConversationId();
  const { getUser } = useGetUser();
  const [waitForConfirmation, setWaitForConfirmation] = useState(false);

  const handleChat = async () => {
    if (!lockedId) return;
    const userId = getUserIdFromLockedId(lockedId);
    if (!userId) return;
    const user = getUser(userId);
    if (!user) return;

    await createConvo(user, (convo) => {
      setSelectedConvoId(convo?.mId);
      setIsMessageHubOpen(true);
    });
  };

  const onDoneClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    await onDone();
  };

  const onForceUnlockConfirm = async () => {
    await onForceUnlock?.();
    setWaitForConfirmation(false);
  };

  const onForceUnlockPress = () => {
    setWaitForConfirmation(true);
  };

  return (
    (readLock || writeLock) && (
      <Container $readLock={readLock} $writeLock={writeLock} $collapsed={collapsed}>
        <Box>
          {readLock && (
            <VStack alignItems="flex-start">
              {isCurrentUser ? (
                <Text
                  variant="listItemLabelMedium"
                  color="blackHighEmphasis"
                  truncate
                  style={{ flex: 1 }}
                >
                  You&rsquo;re editing in another pane...
                </Text>
              ) : (
                <>
                  <Text
                    variant="listItemLabelMedium"
                    color="blackHighEmphasis"
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                  >{`${lockedBy} is editing...`}</Text>
                  {lockedId && (
                    <Text
                      variant="captionRegularUnderline"
                      color="blackHighEmphasis"
                      onClick={handleChat}
                    >
                      Send a message (Open Chat)
                    </Text>
                  )}
                </>
              )}
            </VStack>
          )}
        </Box>
        <ActionButtonsWrapper>
          {writeLock && (
            <>
              {onCancel && (
                <CancelButton
                  usage="significant"
                  variant="outlined"
                  onClick={() => {
                    void onCancel?.();
                  }}
                  height={32}
                >
                  Cancel
                </CancelButton>
              )}

              <SaveButton
                usage="outlined"
                variant="contained"
                onClick={(e) => void onDoneClick(e)}
                height={32}
              >
                Save
              </SaveButton>
            </>
          )}
          {readLock && onForceUnlock && (
            <ClickAwayListener
              onClickAway={() => {
                setWaitForConfirmation(false);
              }}
            >
              <div>
                <ForceUnlockButton
                  usage={waitForConfirmation ? 'danger' : 'outlined'}
                  variant="outlined"
                  height={32}
                  onClick={waitForConfirmation ? onForceUnlockConfirm : onForceUnlockPress}
                  $waitForConfirmation={waitForConfirmation}
                  width="max-content"
                >
                  {waitForConfirmation ? 'Confirm Unlock' : 'Force Unlock'}
                </ForceUnlockButton>
              </div>
            </ClickAwayListener>
          )}
        </ActionButtonsWrapper>
        {(isSaving || isCancelled) && (
          <MessageContainer>
            <Progress variant="indeterminate" disableShrink size={24} thickness={1} />
            <ProgressText>{isCancelled ? 'Cancelling' : 'Saving'}</ProgressText>
          </MessageContainer>
        )}
      </Container>
    )
  );
};

export default LockedIndicator;
