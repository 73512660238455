import { useContext } from 'react';
import groupBy from 'lodash/groupBy';

import useGetConversations from 'api/useGetConversations';
import UserContext from 'contexts/UserContext';
import useCreateConversation from 'hooks/useCreateConversation';
import assembleDepartmentData from 'screens/main/components/header/navbar/messageHub/components/newMessage/utils/assembleDepartmentData';
import assemblePeopleData from 'screens/main/components/header/navbar/messageHub/components/newMessage/utils/assemblePeopleData';
import assembleTeamData from 'screens/main/components/header/navbar/messageHub/components/newMessage/utils/assembleTeamData';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { AssignedMember } from 'types/members';
import { Conversation } from 'types/messageHub';
import conversationTypes from 'utils/constants/conversationTypes';

const useCreateConvo = () => {
  const [createConversation] = useCreateConversation();
  const user = useContext(UserContext);

  const { data } = useGetConversations();

  const groupedData = groupBy(data?.getConversationsOfUser, 'convoType');

  const createConvo = async (member: AssignedMember, onOk: (convo: Conversation) => void) => {
    let convoData = [];
    if (member.mType === ('department' as MemberTypeEnum)) {
      convoData = assembleDepartmentData([member]);
    } else if (member.mType === ('team' as MemberTypeEnum)) {
      convoData = assembleTeamData([member]);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      convoData = assemblePeopleData([member], user.mId, groupedData[conversationTypes.DIRECT]);
    }

    await createConversation(convoData, onOk);
  };

  return { createConvo };
};

export default useCreateConvo;
