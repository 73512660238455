/* eslint-disable sort-imports */
import { useState } from 'react';

import { RowCell } from 'components/editMdfDialog/components/FieldModelRow';
import { Input } from 'components/input/styled';
import Tooltip from 'components/tooltip';
import LWCheckbox from 'features/orderForm/components/LWCheckbox';
import LWSelect from 'features/orderForm/components/LWSelect';
import { HStack } from 'layouts/box/Box';
import { Alternative, AuthType, IntegrationType } from 'types/graphqlTypes';
import { isoToLocaleShort } from 'utils/datetimeHelpers';

import { DeleteButton, ResetButton, StyledRowCell, StyledTableRow } from './styled';

const authOptions: Alternative[] = [
  {
    id: AuthType.None,
    label: 'None',
    value: AuthType.None,
  },
  {
    id: AuthType.Apikey,
    label: 'API key',
    value: AuthType.Apikey,
  },
];

interface PluginRowProps {
  plugin: IntegrationType;
  isSelected: boolean;
  hasChanges: boolean;
  onSelect: (action: IntegrationType) => void;
  onDeletePlugin: (id: string) => void;
  onUpdatePlugin: (updates: Partial<IntegrationType>) => void;
  onResetPlugin: (id: string) => void;
}

export function EditPluginRow({
  plugin,
  hasChanges,
  onDeletePlugin,
  onUpdatePlugin,
  onResetPlugin,
  isSelected,
  onSelect,
}: Readonly<PluginRowProps>) {
  const { mTitle, mDescription, auth, iconUrl, mUpdatedAt, endpoint, mActive, externalId } = plugin;
  const [title, setTitle] = useState(mTitle);
  const [localEndpoint, setLocalEndpoint] = useState(endpoint ?? '');
  const [localIconUrl, setLocalIconUrl] = useState(iconUrl ?? '');
  const [description, setDescription] = useState(mDescription ?? '');

  return (
    <StyledTableRow $selected={isSelected}>
      <StyledRowCell
        style={hasChanges ? { color: 'orange' } : undefined}
        onClick={() => onSelect(plugin)}
      >
        <Tooltip title="Test action">
          <span>
            {externalId}
            {hasChanges ? '*' : ''}
          </span>
        </Tooltip>
      </StyledRowCell>
      <RowCell>
        <Input
          value={title}
          onChange={(ev) => setTitle(ev.target.value)}
          onBlur={() => onUpdatePlugin({ mTitle: title })}
          placeholder="Type menu label here.."
        />
      </RowCell>
      <RowCell>
        <Input
          value={description}
          onChange={(ev) => setDescription(ev.target.value)}
          onBlur={() =>
            onUpdatePlugin({ mDescription: description.length === 0 ? null : description })
          }
          placeholder="Optional description"
        />
      </RowCell>
      <RowCell>
        <Input
          value={localIconUrl}
          onChange={(ev) => setLocalIconUrl(ev.target.value)}
          onBlur={() =>
            onUpdatePlugin({ iconUrl: localIconUrl.length === 0 ? null : localIconUrl })
          }
          placeholder="https://url-to-your-icon.com"
        />
      </RowCell>
      <RowCell>
        <LWSelect
          value={auth?.type ?? AuthType.None}
          options={authOptions}
          setValue={(val) => onUpdatePlugin({ auth: { type: val as AuthType } })}
        />
      </RowCell>
      <RowCell>
        <Input
          value={localEndpoint}
          onChange={(ev) => setLocalEndpoint(ev.target.value)}
          onBlur={() =>
            onUpdatePlugin({ endpoint: localEndpoint.length === 0 ? null : localEndpoint })
          }
          placeholder="REST endpoint"
        />
      </RowCell>
      <RowCell>
        <LWCheckbox selected={mActive} setValue={() => onUpdatePlugin({ mActive: !mActive })} />
      </RowCell>
      <RowCell>{isoToLocaleShort(mUpdatedAt)}</RowCell>
      <RowCell>
        <HStack justifyContent="start">
          <Tooltip title="Discard changes">
            <ResetButton onMouseDown={() => onResetPlugin(plugin.mRefId)} />
          </Tooltip>
          <Tooltip title="Delete field">
            <DeleteButton onMouseDown={() => onDeletePlugin(plugin.mRefId)} />
          </Tooltip>
        </HStack>
      </RowCell>
    </StyledTableRow>
  );
}
