import type { Table } from '@tanstack/react-table';

import { ReactComponent as Settings } from 'assets/icons/systemicons/columns.svg';
import { Button } from 'components/buttons';
import DropdownMenu from 'components/dropdownMenu/DropdownMenu';
import { CheckboxWithLabel } from 'lib/checkbox';

interface DataTableViewOptionsProps<TData> {
  table: Table<TData>;
}

/** View options dropdown menu */
export function DataTableViewOptions<TData>({ table }: Readonly<DataTableViewOptionsProps<TData>>) {
  return (
    <DropdownMenu>
      <DropdownMenu.Trigger>
        <Button
          title="Select which columns to view"
          aria-label="Toggle columns"
          variant="outlined"
          usage="outlined"
          height={32}
          startIcon={<Settings />}
        >
          View
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content>
          <DropdownMenu.Header>Select columns</DropdownMenu.Header>
          <DropdownMenu.Item
            onSelect={(e) => {
              table.toggleAllColumnsVisible();
              e.preventDefault();
            }}
          >
            <CheckboxWithLabel
              label="All"
              checked={table.getIsAllColumnsVisible()}
              onClick={(e) => {
                table.toggleAllColumnsVisible();
                e.preventDefault();
              }}
            />
          </DropdownMenu.Item>
          <DropdownMenu.Separator />
          {table
            .getAllColumns()
            .filter((column) => typeof column.accessorFn !== 'undefined' && column.getCanHide())
            .map((column) => {
              return (
                <DropdownMenu.Item
                  key={`${column.id}}`}
                  onSelect={(e) => {
                    column.toggleVisibility();
                    e.preventDefault();
                  }}
                >
                  <CheckboxWithLabel
                    onClick={(e) => {
                      column.toggleVisibility();
                      e.preventDefault();
                    }}
                    label={(column.columnDef.header as string) ?? column.id}
                    checked={column.getIsVisible()}
                  />
                </DropdownMenu.Item>
              );
            })}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu>
  );
}
