import { useMemo } from 'react';
import { useContextMenu } from 'react-contexify';
import { OnDataOptions } from '@apollo/client';

import Tooltip from 'components/tooltip';
import useApolloSubscription from 'hooks/useApolloSubscriptionV2';
import { UPDATED_ORDERS } from 'operations/subscriptions/notifyOrderUpdate';
import {
  removeOrderFromCache,
  removeOrderFromCacheByType,
  useGetOrders,
  writeOrderToCache,
  writeOrderToCacheByType,
} from 'screens/space/api/useGetOrdersAndForms';
import { useOrderFormFilter, useOrdersDialog } from 'store';
import { RawOrder, ResourceType } from 'types/forms/forms';
import { CrudActionEnum, GetOrderEnum, TaskStatusEnum } from 'types/graphqlTypes';

import {
  StyledButton,
  StyledPlus,
  TaskProgress,
  TaskProgressWrapper,
  TaskText,
  TaskWrapper,
} from '../styled';

import { getActiveFromOrder } from './OrderGridFull';

export interface OrderButtonProps {
  resourceId: string;
  resourceTitle: string;
  resourceType: ResourceType;
  formTypes: string[];
  dark?: boolean;
  onClose?: () => void;
}

interface TaskDetails {
  completed: number;
  total: number;
  pct: number;
}

interface SubscriptionEvent {
  notifyOrderUpdateSubscription: RawOrder;
}

/**
 * We do not subscribe to changes for blocks & orders
 * specifically as these events come in via the story subscription instead.
 */
const skipSubscribe = (resourceType: ResourceType) => ['block', 'order'].includes(resourceType);

function OrderButton({
  resourceId,
  resourceTitle,
  resourceType,
  formTypes,
  dark,
}: Readonly<OrderButtonProps>) {
  const [, setShowOrders] = useOrdersDialog();
  const { show } = useContextMenu({ id: 'orderForms' });
  const { orders, loading } = useGetOrders(resourceId, GetOrderEnum.Resource, TaskStatusEnum.all);
  const [, setFormFilter] = useOrderFormFilter();

  useApolloSubscription(UPDATED_ORDERS, {
    variables: { mIdSubscribed: resourceId },
    skip: !resourceId || skipSubscribe(resourceType),
    source: 'orderButton',
    onSubscriptionData: (data: OnDataOptions<SubscriptionEvent>) => {
      const { data: subscriptionData, client } = data;
      if (!subscriptionData.data) return;
      const order = subscriptionData.data.notifyOrderUpdateSubscription;
      const status = getActiveFromOrder(order);
      switch (order.crudAction) {
        case CrudActionEnum.Remove:
          removeOrderFromCache(client, order.mResourceId, order.mId, status);
          break;
        case CrudActionEnum.Insert:
          writeOrderToCache(client, order.mResourceId, order, status, CrudActionEnum.Insert);
          break;
        case CrudActionEnum.Modify: {
          if (status === TaskStatusEnum.active) {
            writeOrderToCacheByType(
              client,
              resourceId,
              order,
              GetOrderEnum.Resource,
              TaskStatusEnum.active,
              CrudActionEnum.Insert,
            );
          } else if (status === TaskStatusEnum.inactive) {
            removeOrderFromCacheByType(
              client,
              resourceId,
              order.mId,
              GetOrderEnum.Resource,
              TaskStatusEnum.active,
            );
          }
          break;
        }
      }
    },
  });

  const taskDetails = useMemo<TaskDetails>(() => {
    const completedOrders = orders.filter((order) => order.mActive !== true).length;

    const pct = completedOrders !== 0 ? completedOrders / orders.length : 0;
    return {
      pct: pct * 100,
      completed: completedOrders,
      total: orders.length,
    };
  }, [orders]);

  const handleContextMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
    setFormFilter({ types: formTypes ?? null });
    show({
      event,
      props: {
        resourceId: id,
        resourceType,
      },
    });
  };

  const handleShowOrders = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
    if (id) {
      event.stopPropagation();
      setShowOrders({
        resourceId,
        resourceTitle,
        resourceType,
        formTypes,
        open: true,
        type: 'resource',
      });
    }
  };

  return (
    <StyledButton height={32} width={100} variant="outlined" usage="outlined" $dark={dark}>
      <Tooltip title="View tasks">
        <TaskWrapper onClick={(ev) => handleShowOrders(ev, resourceId)}>
          {taskDetails.total === 0 || loading ? (
            <TaskText>{loading ? 'Loading' : 'No tasks'}</TaskText>
          ) : (
            <>
              <TaskText>
                Tasks: {taskDetails.completed} / {taskDetails.total}
              </TaskText>
              <TaskProgressWrapper>
                <TaskProgress $width={taskDetails.pct}></TaskProgress>
              </TaskProgressWrapper>
            </>
          )}
        </TaskWrapper>
      </Tooltip>
      <Tooltip title="Create a task">
        <StyledPlus onClick={(ev) => handleContextMenu(ev, resourceId)}>+</StyledPlus>
      </Tooltip>
    </StyledButton>
  );
}

export default OrderButton;
