import { useCallback, useState } from 'react';
import styled from '@emotion/styled/macro';
import { distanceInWordsToNow } from 'date-fns';

import { ReactComponent as Approval } from 'assets/icons/systemicons/comment/approval.svg';
import { ReactComponent as ApprovalDone } from 'assets/icons/systemicons/comment/approval_done.svg';
import { ReactComponent as More } from 'assets/icons/systemicons/more_vertical.svg';
import Avatar from 'components/avatar/Avatar';
import { IconButton } from 'components/buttons';
import { ConfirmDialog, DeleteDialog } from 'components/dialogs/CommonDialogs';
import Popover from 'components/dialogs/PopoverBuilder';
import Editor from 'components/editor';
import variants from 'components/editor/constants/types/editorVariants';
import ListItem from 'components/listItem';
import Text from 'components/text/Text';
import useGetUser from 'hooks/useGetUser';
import { Box, HStack, VStack } from 'layouts/box/Box';
import { EditorValue } from 'types/editor';
import { User } from 'types/members';
import { Conversation } from 'types/messageHub';

import useCreateComments from './hooks/useCreateComments';
import CommentInput from './CommentInput';

const Container = styled(VStack)<{ showDivider: boolean }>`
  background: ${({ theme }) => theme.palette.dina.headerFooterOverlay};
  border-bottom: ${({ showDivider, theme }) =>
    showDivider ? `1px solid ${theme.palette.dina.dividerLight}` : 'none'};
`;

const StyledListItem = styled(ListItem)`
  width: 100%;
`;

const TitleWrapper = styled('div')`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

function ThreadDetails({
  comment,
  currentUserId,
  showDivider,
  openUserDetails,
  setOpen,
}: {
  comment: Conversation;
  currentUserId: string;
  showDivider: boolean;
  openUserDetails: (user?: User) => void;
  setOpen: (val: boolean) => void;
}) {
  const { getUser } = useGetUser();
  const [createComment] = useCreateComments();

  const [editing, setEditing] = useState(false);
  const [openDoneConfirm, setOpenDoneConfirm] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const createdBy = getUser(comment?.mCreatedById ?? '');
  const updatedBy = getUser(comment?.mUpdatedById ?? '');

  const updateComment = useCallback(
    async (newComment?: EditorValue) => {
      setLoading(true);
      if (newComment) {
        await createComment({
          ...comment,
          mContent: JSON.stringify(newComment),
          crudAction: 'UPDATE',
        });
      }
      setEditing(false);
      setLoading(false);
    },
    [comment, createComment],
  );

  const updateCommentState = useCallback(async () => {
    setLoading(true);
    await createComment({
      ...comment,
      mState: comment.mState === 'created' ? 'done' : 'created',
      crudAction: 'UPDATE',
    });
    setOpenDoneConfirm(false);
    setLoading(false);
  }, [comment, createComment]);

  const deleteComment = useCallback(async () => {
    setLoading(true);
    try {
      await createComment({
        ...comment,
        crudAction: 'DELETE',
      });
    } finally {
      setOpenDeleteConfirm(false);
      setLoading(false);
      setOpen(false);
    }
  }, [comment, setOpen, setLoading, setOpenDeleteConfirm, createComment]);

  return (
    <Container width="100%" padding="8px" flex="1 0 auto" showDivider={showDivider}>
      <HStack width="100%" gap="8px">
        <Avatar title={createdBy?.mTitle} imageKey={createdBy?.mAvatarKey} size={32} />
        <VStack flex="1" alignItems="flex-start">
          <TitleWrapper onClick={() => openUserDetails(createdBy)}>
            <Text variant="listItemLabel" color="highEmphasis">
              {createdBy?.mTitle}
            </Text>
          </TitleWrapper>
          <Text variant="caption" color="watermark">
            Created {distanceInWordsToNow(comment?.mCreatedAt ?? '', { addSuffix: true })}
          </Text>
          {comment.mCreatedAt !== comment.mUpdatedAt && (
            <Text variant="caption" color="watermark">
              Updated {distanceInWordsToNow(comment?.mUpdatedAt ?? '', { addSuffix: true })} by{' '}
              {updatedBy?.mTitle}
            </Text>
          )}
        </VStack>
        {comment.mState && (
          <>
            <IconButton
              title={comment.mState === 'created' ? 'Mark as resolved' : 'Reopen thread'}
              usage="text"
              onClick={() => setOpenDoneConfirm(true)}
            >
              {comment.mState === 'done' ? <ApprovalDone className="skipOverride" /> : <Approval />}
            </IconButton>
            <ConfirmDialog
              open={openDoneConfirm}
              onClose={() => setOpenDoneConfirm(false)}
              onClick={updateCommentState}
              title={comment.mState === 'created' ? 'Mark thread as resolved?' : 'Reopen thread?'}
              message={
                comment.mState === 'created'
                  ? `Are you sure you want to mark this comment thread as resolved?
                  No further comments can be added unless the thread is reopened.`
                  : `Are you sure you want to reopen this thread?
                  It will no longer be marked as resolved, and new comments can be added.`
              }
              confirmLabel="Confirm"
              loading={loading}
              overlayStyle={{ zIndex: 1400 }}
            />
          </>
        )}
        {currentUserId === createdBy?.mId && (
          <Popover>
            <Popover.Trigger asChild>
              <span>
                <IconButton usage="text" size={24} iconSize={16}>
                  <More />
                </IconButton>
              </span>
            </Popover.Trigger>
            <Popover.Content style={{ minWidth: 220, overflow: 'hidden' }}>
              <VStack width="100%" alignItems="flex-start" padding="0px">
                <StyledListItem
                  text="Edit"
                  info="Edit comment"
                  onClick={() => setEditing(!editing)}
                  icon={undefined}
                />
                <StyledListItem
                  text="Delete"
                  info="Delete thread"
                  onClick={() => setOpenDeleteConfirm(true)}
                  icon={undefined}
                />
                <DeleteDialog
                  open={openDeleteConfirm}
                  onClose={() => setOpenDeleteConfirm(false)}
                  onClick={deleteComment}
                  title="Delete Comment Thread?"
                  message={`Are you sure you want to delete this entire thread?
                    All comments in this thread will be permanently deleted
                    and cannot be restored.`}
                  confirmLabel="Delete"
                  loading={loading}
                  overlayStyle={{ zIndex: 1400 }}
                />
              </VStack>
            </Popover.Content>
          </Popover>
        )}
      </HStack>
      {editing ? (
        <Box width="100%" margin="8px 0 0">
          <CommentInput
            onSave={updateComment}
            initialValue={JSON.parse(comment?.mContent ?? '{}') as EditorValue}
            isEditing={editing}
          />
        </Box>
      ) : (
        <Editor
          showHoveringTooltip={false}
          background="transparent"
          renderToolbar={() => null}
          height="100%"
          readOnly={true}
          padding={0}
          value={JSON.parse(comment?.mContent ?? '{}') as EditorValue}
          placeholder=""
          variant={variants.MESSAGE}
        />
      )}
    </Container>
  );
}

export default ThreadDetails;
