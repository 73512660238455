import { useMemo } from 'react';
import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  split,
  HttpLink,
  defaultDataIdFromObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BrowserRouter as Router } from 'react-router-dom';
import { onError } from '@apollo/client/link/error';

import { AuthProvider } from 'contexts/AuthContext';
import { getAuthConfiguration } from 'features/auth';
import { getCoverPhotoUrl, getThumbnailUrl } from 'operations/localResolvers/urlResolvers';
import resolveInstanceCache from 'operations/localResolvers/resolveInstanceCache';
import resolveRundownInstancesCache from 'operations/localResolvers/resolveRundownInstancesCache';
import Theme from 'theme/Theme';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import { useShowError } from 'store';

const awsConfig = {
  ...getAuthConfiguration(),
  Storage: {
    AWSS3: {
      bucket: import.meta.env.REACT_APP_S3_BUCKET_NAME,
      region: import.meta.env.REACT_APP_AWS_APPSYNC_REGION,
    },
  },
};

Amplify.configure(awsConfig);
Auth.configure(awsConfig);

const endpoint = import.meta.env.REACT_APP_API_ENDPOINT;
const region = import.meta.env.REACT_APP_AWS_APPSYNC_REGION;

const getUserInfo = async () => {
  const user = await Auth.currentAuthenticatedUser();
  let sub = null;
  let claims = [];
  if (user) {
    const token = user.getSignInUserSession().getIdToken();
    claims = token?.payload['cognito:groups'] || [];
    sub = token.payload['custom:subalias'] || token?.payload?.sub;
  }

  return {
    sub,
    claims,
  };
};

const queryClient = new QueryClient();

const resolvers = {
  Query: {
    instance: resolveInstanceCache,
    rundownInstances: resolveRundownInstancesCache,
  },
  MemberType: {
    mThumbnailUrl: getThumbnailUrl,
    mAvatarUrl: getCoverPhotoUrl,
    mContent: (_parent, _args, { client: _cl }) => null,
  },
  Rundown: {
    mThumbnailUrl: getThumbnailUrl,
    mCoverPhotoUrl: getCoverPhotoUrl,
  },
};

const auth = {
  type: 'AWS_IAM', // TODO: Read it from config
  credentials: () => Auth.currentCredentials(),
};

const httpLink = new HttpLink({
  uri: endpoint,
});

const apolloLink = ApolloLink.from([
  createAuthLink({ url: endpoint, region, auth }),
  setContext(async (request, previousContext) => {
    const { sub, claims } = await getUserInfo();
    return {
      headers: {
        ...previousContext.headers,
        claims,
        sub,
      },
    };
  }),
  split(
    (op) => {
      const { operation } = op?.query?.definitions[0] ?? {};
      return operation !== 'subscription';
    },
    httpLink,
    createSubscriptionHandshakeLink(
      {
        auth,
        region,
        url: endpoint,
      },
      httpLink,
    ),
  ),
]);

const possibleTypes = {
  mProperties: [
    'AutomationTemplateConfigType',
    'ContactType',
    'ScheduleEntryProperties',
    'GroupPolicy',
    'NoteProperties',
    'MetadataFormType',
    'PlatformType',
    'InstanceConfigType',
    'StatusViewType',
    'RundownStateType',
    'AuditInstanceStateType',
    'AuditAssetType',
    'AuditPublishingType',
    'AuditMemberType',
  ],
};

function nullable() {
  // Create a generic field policy that allows any field to be null by default:
  return {
    read(existing = null) {
      return existing;
    },
  };
}

const typePolicies = {
  MemberType: {
    fields: {
      mProperties: {
        merge: true,
      },
      mSyncProviders: {
        merge: true,
      },
    },
  },
  Rundown: {
    fields: {
      recurrence: {
        merge: true,
      },
    },
  },

  Query: {
    fields: {
      searchApi: {
        keyArgs: false,
        merge: (existing, incoming) => {
          const items = existing ? [...existing.items] : [];
          const incomingItems = incoming ? [...incoming.items] : [];
          const merged = [...items, ...incomingItems];
          return {
            nextToken: incoming?.nextToken,
            items: merged,
          };
        },
      },
    },
  },
};

function AppProvider({ children }) {
  const [, setShowError] = useShowError();
  const errorLink = onError((errorHandler) => {
    const { networkError, operation } = errorHandler;
    if (operation.getContext().errorHandledLocally) {
      return;
    }

    if (networkError?.includes('not authenticated')) {
      setShowError(true);
    }
  });

  const client = useMemo(
    () =>
      new ApolloClient({
        link: ApolloLink.from([errorLink, apolloLink]),
        cache: new InMemoryCache({
          dataIdFromObject: (obj) => {
            const { mId, mRefId, __typename, provider, assetRefId, mEventId } = obj ?? {};
            const defaultKey = mId !== mRefId ? `${mId}:${mRefId}` : mId;
            if (__typename === 'SyncProviderType') return defaultDataIdFromObject(obj);
            if (__typename === 'MetadataFormViewColumnType') {
              return `MetadataFormViewColumnType:${obj?.columnId}`;
            }
            if (!mId) return defaultDataIdFromObject(obj);
            if (__typename === 'FeedItem') return `${provider}:${mId}`;
            if (__typename === 'MediaAssetType') return `mediaAsset:${mId}:${assetRefId}`;
            if (__typename === 'SearchItem') return `searchItem:${defaultKey}`;
            if (__typename === 'AuditType') return `auditItem:${mId}:${mEventId}`;
            return defaultKey;
          },
          possibleTypes,
          typePolicies: {
            MemberType: {
              fields: {
                mRundownTemplateId: nullable(),
                metadata: nullable(),
                mAvatarUrl: nullable(),
                mAvatarKey: nullable(),
                mDescription: nullable(),
              },
            },
            Rundown: {
              fields: {
                mdfId: nullable(),
                metadata: nullable(),
                permissions: nullable(),
              },
            },
            Recurrence: {
              fields: {
                startTime: nullable(),
                dailyExclusive: nullable(),
              },
            },
            SearchItem: {
              fields: {
                highlight: nullable(),
                mCreatedById: nullable(),
                mStoryId: nullable(),
                mProperties: nullable(),
                mPlannedDuration: nullable(),
                mCreatedAt: nullable(),
                mTemplateId: nullable(),
                metadata: nullable(),
              },
            },
            Query: {
              fields: {
                getFeeds: {
                  merge(existing, incoming, { readField }) {
                    const existingNextToken = existing?.nextToken;
                    const incomingNextToken = incoming?.nextToken;

                    let merged = existing?.items ? existing.items.slice(0) : [];
                    let incomingItems = incoming?.items ? incoming.items.slice(0) : [];

                    // Update incoming items already present in the existing data.
                    merged = merged.map((existingItem) => {
                      const existingId = readField('mId', existingItem);
                      const incomingItem = incomingItems.find(
                        (item) => readField('mId', item) === existingId,
                      );

                      return incomingItem ?? existingItem;
                    });

                    // Obtain a Set of all existing item IDs.
                    const existingIdSet = new Set(merged.map((item) => readField('mId', item)));
                    // Remove incoming items already present in the existing data.
                    incomingItems = incomingItems?.filter(
                      (item) => !existingIdSet.has(readField('mId', item)),
                    );

                    // append the rest of the incoming items to the merged array.
                    merged.push(...incomingItems);

                    // Sort the merged array by mPublishedAt date.
                    merged.sort(
                      (a, b) =>
                        new Date(readField('mPublishedAt', b)).getTime() -
                        new Date(readField('mPublishedAt', a)).getTime(),
                    );

                    // if existing, use the one with the lowest number, otherwise incoming
                    const newNextToken = existingNextToken
                      ? Math.min(existingNextToken, incomingNextToken)
                      : incomingNextToken;

                    // Return the merged result with the updated nextToken.
                    return {
                      ...incoming,
                      nextToken: newNextToken,
                      items: merged,
                    };
                  },
                },
              },
            },
          },
        }),
        typePolicies,
        resolvers,
        defaultOptions: {
          watchQuery: {
            nextFetchPolicy(lastFetchPolicy) {
              if (lastFetchPolicy === 'cache-and-network' || lastFetchPolicy === 'network-only') {
                return 'cache-first';
              }
              return lastFetchPolicy;
            },
          },
        },
      }),
    [],
  );
  return (
    <Theme>
      <ErrorBoundary apolloClient={client}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <ApolloProvider client={client}>
              <Router>{children}</Router>
            </ApolloProvider>
          </AuthProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </Theme>
  );
}

export default AppProvider;
