import type { Column } from '@tanstack/react-table';

import { ReactComponent as CheckIcon } from 'assets/icons/systemicons/check.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/systemicons/filters_off.svg';
import Badge, { BadgeVariants } from 'components/badge';
import { Button } from 'components/buttons';
import Popover from 'components/dialogs/PopoverBuilder';
import Divider from 'components/divider';
import type { Option } from 'features/grids/common/types';
import { HStack } from 'layouts/box/Box';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'lib/command';

import { CheckIconWrapper, OptionCount } from './styled';

interface DataTableColumnFilterProps<TData, TValue> {
  column?: Column<TData, TValue>;
  title?: string;
  options: Option[];
}

export function DataTableColumnFilter<TData, TValue>({
  column,
  title,
  options,
}: Readonly<DataTableColumnFilterProps<TData, TValue>>) {
  const selectedValues = new Set(column?.getFilterValue() as string[]);

  return (
    <Popover>
      <Popover.Trigger asChild>
        <span>
          <Button
            variant="dashed"
            usage="outlined"
            align="left"
            height={32}
            title={`Filter on ${title}`}
          >
            <FilterIcon />
            {title}
            {selectedValues?.size > 0 && (
              <>
                <Divider orientation="vertical" style={{ margin: '2px' }} />
                <HStack gap="4px" overflow="hidden">
                  {selectedValues.size > 2 ? (
                    <Badge variant={BadgeVariants.INPUT}>{selectedValues.size} selected</Badge>
                  ) : (
                    options
                      .filter((option) => selectedValues.has(option.value))
                      .map((option) => (
                        <Badge variant={BadgeVariants.INPUT} key={option.value}>
                          {option.label}
                        </Badge>
                      ))
                  )}
                </HStack>
              </>
            )}
          </Button>
        </span>
      </Popover.Trigger>
      <Popover.Content>
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup style={{ paddingBottom: '8px' }}>
              {options.map((option, index) => {
                const isSelected = selectedValues.has(option.value);

                return (
                  <CommandItem
                    key={option.value ?? index}
                    style={{
                      display: 'flex',
                      textAlign: 'center',
                      height: '32px',
                      gap: '12px',
                      borderRadius: '4px',
                      paddingInline: '8px',
                    }}
                    onSelect={() => {
                      if (isSelected) {
                        selectedValues.delete(option.value);
                      } else {
                        selectedValues.add(option.value);
                      }
                      const filterValues = Array.from(selectedValues);
                      column?.setFilterValue(filterValues.length ? filterValues : undefined);
                    }}
                  >
                    <CheckIconWrapper isSelected={isSelected}>
                      <CheckIcon />
                    </CheckIconWrapper>
                    {option.icon && <option.icon />}
                    <span>{option.label}</span>
                    {option.withCount && column?.getFacetedUniqueValues()?.get(option.value) && (
                      <OptionCount>
                        {column?.getFacetedUniqueValues().get(option.value)}
                      </OptionCount>
                    )}
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedValues.size > 0 && (
              <CommandGroup style={{ paddingBottom: '8px' }}>
                <CommandItem
                  onSelect={() => column?.setFilterValue(undefined)}
                  style={{
                    justifyContent: 'center',
                    textAlign: 'center',
                    height: '32px',
                    borderRadius: '4px',
                  }}
                >
                  Clear filters
                </CommandItem>
              </CommandGroup>
            )}
          </CommandList>
        </Command>
      </Popover.Content>
    </Popover>
  );
}
