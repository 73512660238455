import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

/** Controls the toolbar displayed on the different screens */
const toolbarAtom = atom(<div />);

export const useToolbar = () => useAtom(toolbarAtom);

/* Control the loading indicator to be shown on Rundown or Story screen */
const loadingIndicatorAtom = atom('');

export const useLoadingIndicator = () => useAtom(loadingIndicatorAtom);

const storyViewAtom = atomWithStorage<'v1' | 'v2'>('storyView', 'v2');
export const useStoryView = () => useAtom(storyViewAtom);

/* Scroll instance into story view */
const scrollIntoViewAtom = atom<string | null>(null);
export const useScrollIntoView = () => useAtom(scrollIntoViewAtom);

/* Show ReloadOnAuthError Modal */
const showErrorAtom = atom(false);
export const useShowError = () => useAtom(showErrorAtom);
