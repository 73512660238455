import { memo, useCallback, useMemo, useState } from 'react';
import { ScopeProvider } from 'jotai-molecules';

import DebouncedLoadingIndicator from 'components/debouncedLoadingIndicator/DebouncedLoadingIndicator';
import Infobar from 'components/infobar';
import useCreateNoteIfNotFound from 'features/note/api/useCreateNoteIfNotFound';
import Note from 'features/note/Note';
import { NoteScope, NoteScopeObject } from 'features/notes/store';
import NotePrint from 'features/print/NotePrint';
import WidgetWrapper from 'features/widget/components/WidgetWrapper';
import { WIDGETS } from 'features/widget/constants';
import { Flex } from 'layouts/box/Box';

import { NoteWidgetProps } from './types';

const NoteWidget = ({
  mId,
  mRefId,
  title,
  writeAccess = false,
  viewRef,
  spaceId,
  layout,
}: Readonly<NoteWidgetProps>) => {
  const entity = useMemo(
    () => ({ mId: spaceId!, mRefId, mStoryId: viewRef! }),
    [spaceId, mRefId, viewRef],
  );
  const [printDialogOpen, setPrintDialogOpen] = useState(false);

  const onPrint = useCallback(() => {
    setPrintDialogOpen(true);
  }, []);

  const onClosePrintDialog = useCallback(() => {
    setPrintDialogOpen(false);
  }, []);

  const { data, loading, error } = useCreateNoteIfNotFound(entity);

  const scopeValue: NoteScopeObject = useMemo(
    () => ({ view: 'widget', viewRef: viewRef as string }),
    [viewRef],
  );

  return (
    <WidgetWrapper
      mId={mId}
      title={title}
      writeAccess={writeAccess}
      type={WIDGETS.NOTE}
      mRefId={mRefId}
      layout={layout}
      onPrint={onPrint}
    >
      <ScopeProvider scope={NoteScope} value={JSON.stringify(scopeValue)}>
        <Flex flex="1 0 100%" overflow="hidden" flexDirection="column">
          <DebouncedLoadingIndicator isLoading={loading} />
          {error && (
            <Flex padding="0.5rem">
              <Infobar>{`There was an error fetching the note: ${error.message} .`}</Infobar>
            </Flex>
          )}
          {data && <Note note={data} canUpdate={writeAccess} />}
          {printDialogOpen && data && (
            <NotePrint
              isDialogOpen={true}
              onCloseDialog={onClosePrintDialog}
              note={{ ...data, mTitle: title }}
              storyId={spaceId as string}
            />
          )}
        </Flex>
      </ScopeProvider>
    </WidgetWrapper>
  );
};

export default memo(NoteWidget);
